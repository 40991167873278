/* eslint-disable @typescript-eslint/no-explicit-any */
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Remove from '@mui/icons-material/Remove';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { add } from 'date-fns/add';
import { isBefore } from 'date-fns/isBefore';
import Decimal from 'decimal.js';
import { Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { confirmDeferredOrder, createOrUpdateOrder } from '../../../api/customerOrders';
import { orderNowForPickupTomorrow, orderNowForPickupWhenOpen, placeOrder, proceedToPayment } from '../../../common/constants';
import { OrderingAvailabilityStatus, PaymentType, ProductCategoryType } from '../../../common/enums';
import LocalStorageSvc, {
  SELECTED_SITE_KEY,
} from '../../../common/localStorageSvc';
import {
  calculateGSTBreakdown,
  calculateItemPrice,
  displayPrice,
} from '../../../common/moneyFunctions';
import { getOrderItemKey } from '../../../common/orderFunctions';
import { calculateTotalPlusSurcharge } from '../../../common/surchargeFunctions';
import { earliestPickupTime, latestPickupTime } from '../../../common/timeFunctions';
import { CustomerOrder, Order, OrderItem, OrderItemWithQty } from '../../../common/types';
import ProductCategoryTypeIcon from '../../../components/icons/ProductCategoryTypeIcon';
import OrderItemVariationsModal from '../../../components/modal/OrderItemVariationsModal';
import useComponentTypes from '../../../datastore/useComponentTypes';
import useSites from '../../../datastore/useSites';
import useAlert from '../../../hooks/useAlert';
import useLoadingModal from '../../../hooks/useLoadingModal';
import useSelectedSite from '../../../hooks/useSelectedSite';
import CurrentWaitTime from '../components/CurrentWaitTime';
import OrderLockoutBanner from '../components/OrderLockoutBanner';
import useAuthStore from '../../../datastore/useAuth';
import {
  decreaseQuantity,
  increaseQuantity,
  orderToCustomerOrder,
  productNameWithSize,
  productVariantsWithQty,
  summarizeOrderItems,
} from './checkoutFunctions';
import PaymentSection from './PaymentSection';
import PickupTime from './PickupTime';
import TotalBreakdown from './TotalBreakdown';

const emptyOrder: Partial<Order> = {
  requestedPickupTime: new Date(),
  siteId: LocalStorageSvc.load<number>(SELECTED_SITE_KEY) ?? 0,
  orderItems: [],
};

let debouncedUpdateTimer: NodeJS.Timeout | null = null;

const getOrderButtonText = (requestedPickupTime: Date, earliestOrderAt: Date, paymentType: string, orderingStatus: OrderingAvailabilityStatus) => {
  if (orderingStatus === OrderingAvailabilityStatus.Tomorrow) {
    return orderNowForPickupTomorrow
  }
  if (orderingStatus === OrderingAvailabilityStatus.Today && isBefore(requestedPickupTime, earliestOrderAt)) {
    return orderNowForPickupWhenOpen
  }
  if (paymentType === PaymentType.Defer) {
    return placeOrder
  }
  return proceedToPayment
}

export default function CustomerCheckoutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { currentUser: currentCustomer, setShowAccountModal } = useAuthStore();
  const { milkType } = useComponentTypes()
  const { setLoading } = useLoadingModal();
  const { selectedSiteId } = useSelectedSite();
  const { getSiteOrderingAvailabilityStatus } = useSites();
  const defaultOrder = { ...emptyOrder, ...(location.state ?? {}) };
  const [order, setOrder] = useState<Partial<Order>>(defaultOrder);
  const [editOrderItem, setEditOrderItem] = useState<OrderItemWithQty | null>(
    null,
  );

  const [readyToPay, setReadyToPay] = useState<boolean>(false);
  const summarisedOrderItems = summarizeOrderItems(order.orderItems || []);

  const onlineOrderingStatus = getSiteOrderingAvailabilityStatus(selectedSiteId)

  const today = new Date()
  let now = new Date(today);

  if (now > latestPickupTime(now)) {
    now = add(now, { days: 1 });
    now = earliestPickupTime(now);
  }

  const earliestOrderAt = earliestPickupTime(now);

  const orderBtnText = getOrderButtonText(
    order.requestedPickupTime ? new Date(order.requestedPickupTime) : new Date(),
    earliestOrderAt,
    order.paymentType ?? PaymentType.Stripe,
    onlineOrderingStatus
  )

  const updateOrderOnServer = async (
    payload: Partial<Order>,
  ): Promise<CustomerOrder | null> => {
    setLoading(true);
    try {
      const res = await createOrUpdateOrder(orderToCustomerOrder(payload));
      if (res.status === 200 && res.data) {
        setOrder((prev) => ({ ...prev, id: res.data!.id! }));
        return res.data;
      } else {
        showAlert('Something went wrong :(', 'error');
        return null;
      }
    } catch (err: any) {
      console.error(err)
      if (err.data) {
        showAlert(err.data, 'error');
      } else {
        showAlert('Something went wrong :(', 'error');
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSetReadyToPay = async () => {
    const orderSaved = await updateOrderOnServer(order);
    if (!orderSaved) {
      return
    }
    if (orderSaved && order.paymentType !== PaymentType.Defer) {
      setReadyToPay(true);
      return
    }
    await confirmDeferredOrder(orderSaved.id!);
    navigate(`/orderconfirmed/${orderSaved.id}`, { replace: true });
  };

  const backToOrder = () => {
    navigate('/', { state: order });
  };
  const cancelOrder = () => {
    navigate('/');
  };

  const debouncedUpdate = (payload: Partial<Order>) => {
    if (debouncedUpdateTimer) {
      clearTimeout(debouncedUpdateTimer);
    }
    debouncedUpdateTimer = setTimeout(() => updateOrderOnServer(payload), 500);
  };

  const handleOrderUpdate = (payload: Partial<Order>) => {
    const updated = { ...order, ...payload };
    setOrder(updated);
    updated.id && debouncedUpdate(updated);
  }

  const addAnotherItem = (item: OrderItem) => {
    const orderItems = increaseQuantity(item, order.orderItems);
    const updated = { ...order, orderItems };
    handleOrderUpdate(updated);
  };

  const removeAnItem = (item: OrderItem) => {
    const orderItems = decreaseQuantity(item, order.orderItems);
    const updated = { ...order, orderItems };
    handleOrderUpdate(updated);
  };

  const closeOrderItemModal = (orderItem: OrderItem | null) => {
    if (orderItem && editOrderItem) {
      const updatedOrder = { ...order };
      const originalEditOrderItemKey = getOrderItemKey(editOrderItem);
      updatedOrder.orderItems = (updatedOrder.orderItems || []).filter(
        (f) => getOrderItemKey(f) !== originalEditOrderItemKey,
      );
      for (let i = 0; i < editOrderItem.quantity; i++) {
        updatedOrder.orderItems.push(orderItem);
      }
      handleOrderUpdate(updatedOrder);
    }
    setEditOrderItem(null);
  };

  const openOrderItemModal = (orderItem: OrderItemWithQty) => {
    setEditOrderItem({ ...orderItem });
  };

  const { total, subtotal, gst } = calculateGSTBreakdown(
    order.orderItems || [],
  );

  const totalPlusSurcharge = calculateTotalPlusSurcharge(total, order.surchargeFixed, order.surchargePercentage);
  const surcharge = totalPlusSurcharge !== null ? totalPlusSurcharge.minus(total) : null
  const hasSurcharge = !!surcharge && !!totalPlusSurcharge

  const hasItems = summarisedOrderItems.length > 0

  return (
    <>
      <OrderLockoutBanner />
      <CurrentWaitTime />
      <Stack gap={1} sx={{ paddingX: 2, paddingTop: 1 }}>
        <Stack
          flexDirection="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <IconButton onClick={backToOrder}>
            <ProductCategoryTypeIcon category={ProductCategoryType.HotCoffee} />
          </IconButton>
          <Typography
            variant="h3"
            fontWeight="bold"
            textAlign="center"
            sx={{ flex: 1 }}
          >
            Checkout
          </Typography>
          <IconButton onClick={cancelOrder}>
            <DeleteIcon />
          </IconButton>
        </Stack>
        {
          hasItems && <PickupTime order={order} setOrder={handleOrderUpdate} />
        }
        {
          onlineOrderingStatus !== OrderingAvailabilityStatus.Unavailable ? (
            <TextField
              variant='outlined'
              select
              size='small'
              label="Payment Method"
              slotProps={{ inputLabel: { sx: { fontWeight: 'bold', color: 'black' } } }}
              value={order.paymentType ?? PaymentType.Stripe}
              onChange={(e) => handleOrderUpdate({ paymentType: e.target.value as PaymentType })}>
              <MenuItem value={PaymentType.Stripe}>Credit Card</MenuItem>
              <MenuItem value={PaymentType.Defer}>Pay at window</MenuItem>
            </TextField>
          ) : null
        }
        <Typography fontWeight="bold" marginTop={1}>Order Summary</Typography>
        {hasItems ? (
          <>
            <List dense>
              {summarisedOrderItems.map((item: OrderItemWithQty) => {
                return (
                  <Fragment key={getOrderItemKey(item)}>
                    <ListItem
                      divider={!item.notes?.length}
                      sx={{ padding: 0, alignItems: 'center' }}
                    >
                      <ListItemText
                        primary={
                          <span
                            style={{ textDecoration: 'underline' }}
                            onClick={() => openOrderItemModal(item)}
                          >
                            {productNameWithSize(item)}
                          </span>
                        }
                        secondary={productVariantsWithQty(item, milkType?.id)}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: 'bold',
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                          fontSize: 10,
                        }}
                        sx={
                          item.notes?.length
                            ? { marginBottom: 0, paddingBottom: 0 }
                            : undefined
                        }
                      />
                      <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                        <IconButton onClick={() => removeAnItem(item)}>
                          <Remove />
                        </IconButton>
                        <Typography
                          sx={{ width: 30, textAlign: 'center' }}
                        >{`${item.quantity}`}</Typography>
                        <IconButton onClick={() => addAnotherItem(item)}>
                          <Add />
                        </IconButton>
                      </Stack>
                      <Typography fontWeight="bold">
                        {displayPrice(
                          calculateItemPrice(item).times(
                            new Decimal(item.quantity),
                          ),
                        )}
                      </Typography>
                    </ListItem>
                    {item.notes ? (
                      <ListItem
                        divider
                        sx={{ padding: 0, alignItems: 'center', marginTop: 0 }}
                      >
                        <ListItemText
                          secondary={item.notes}
                          secondaryTypographyProps={{
                            variant: 'caption',
                            fontSize: 10,
                          }}
                          sx={{ marginTop: 0 }}
                        />
                      </ListItem>
                    ) : null}
                  </Fragment>
                )
              })}

              <TotalBreakdown
                title="Subtotal"
                subtitle=" (ex gst)"
                amount={displayPrice(subtotal)}
              />
              <TotalBreakdown title="GST" amount={displayPrice(gst)} />
              <TotalBreakdown
                title="Order Total"
                subtitle=" (inc gst)"
                amount={displayPrice(total)}
                isTotal
              />
              {
                hasSurcharge && order.paymentType !== PaymentType.Defer && (
                  <>
                    <TotalBreakdown
                      title="Surcharge"
                      subtitle=" (online payment fee)"
                      amount={displayPrice(surcharge)}
                    />
                    <TotalBreakdown
                      title="Total"
                      subtitle=" (order total + surcharge)"
                      amount={displayPrice(totalPlusSurcharge)}
                      isTotal
                    />
                  </>
                )
              }
            </List>
            {
              onlineOrderingStatus === OrderingAvailabilityStatus.Unavailable ? (
                <OrderLockoutBanner />
              ) : !currentCustomer ? (
                <>
                  <Typography>
                    You need to be logged in to place your order
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => setShowAccountModal('login')}
                  >
                    Log in
                  </Button>
                </>
              ) : (order.paymentType === PaymentType.Defer || !readyToPay) ? (
                <Button variant="contained" onClick={handleSetReadyToPay}>
                  {orderBtnText}
                </Button>
              ) : (
                // must have an order.id at this point, it would have been created in handleSetReadyToPay
                <PaymentSection amount={totalPlusSurcharge ?? total} orderId={order.id!} />
              )
            }
            {!!editOrderItem && (
              <OrderItemVariationsModal
                item={editOrderItem}
                onClose={closeOrderItemModal}
                submitText="Update cart"
              />
            )}
          </>
        ) : (
          <Typography>No items added to order yet</Typography>
        )}
      </Stack>
    </>
  );
}
