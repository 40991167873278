import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import AlertCoordinator from './components/alerts/AlertCoordinator.tsx';
import IsOnlineCoordinator from './components/isOnline/IsOnlineCoordinator.tsx';
import LoadingModalCoordinator from './components/modal/LoadingModalCoordinator.tsx';
import AllRoutes from './Routes';
import { themeOptions } from './theme/theme.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={themeOptions}>
        <CssBaseline />
        <AllRoutes />
        <IsOnlineCoordinator />
        <LoadingModalCoordinator />
        <AlertCoordinator />
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
);
