import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useRef, useState } from 'react';
import {
  createComponentType,
  updateComponentType,
} from '../../../api/componentTypes';
import { hasAdminRole } from '../../../common/roleFunctions';
import { TpgeComponentType } from '../../../common/types';
import useAuthStore from '../../../datastore/useAuth';
import useComponentTypes from '../../../datastore/useComponentTypes';
import useAlert from '../../../hooks/useAlert';
import { AdminHeader } from '../components/AdminHeader';
import { ComponentTypesFilter } from './components/ComponentTypesFilter';
import ComponentTypesModal from './components/ComponentTypesModal';
import { ComponentTypesTable } from './components/ComponentTypesTable';

const ComponentTypesPerPage = 200;

const filterData = (
  components: TpgeComponentType[] = [],
  showDisabled = false,
  filterText = '',
): TpgeComponentType[] => {
  const lowerFilter = filterText.toLowerCase();
  const data = components
    .filter(
      (component) =>
        (showDisabled || component.isActive === true) &&
        (filterText.length
          ? component.name.toLowerCase().includes(lowerFilter)
          : true),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return data;
};

const newObj = (): TpgeComponentType => ({ id: 0, name: '', isActive: true, activeComponentCount: 0, isLockedForUserInterface: false });

export default function ComponentTypesPage() {
  const { currentUser } = useAuthStore();
  const [showDisabled, setShowDisabled] = useState(false);
  const {
    componentTypes,
    loading,
    fetchComponentTypes: refetch,
  } = useComponentTypes();
  const { showAlert } = useAlert();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [filterText, setFilterText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [mutating, setMutating] = useState(false);
  const [mutateObj, setMutateObj] = useState<TpgeComponentType | null>(null);

  const isLoading = loading || mutating;

  if (!hasAdminRole(currentUser)) return null;

  async function handleSave(obj: TpgeComponentType) {
    const mode = obj.id > 0 ? 'edit' : 'create';
    const mutateFn =
      mode === 'edit' ? updateComponentType : createComponentType;
    const errorText = mode === 'edit' ? 'updating' : 'creating';
    const successText = mode === 'edit' ? 'updated' : 'created';
    setMutating(true);
    try {
      const result = await mutateFn(obj);
      if (result.status !== 200) {
        showAlert(
          `Error ${errorText} ComponentType, refresh and try again`,
          'error',
        );
        return;
      } else {
        showAlert(`Component Type ${successText} successfully`, 'success');
        refetch();
      }
      if (mutateObj) {
        setMutateObj(null);
      }
    } catch (err) {
      showAlert(
        `Error ${errorText} ComponentType, refresh and try again`,
        'error',
      );
    } finally {
      setMutating(false);
    }
  }

  async function toggleActive(obj: TpgeComponentType) {
    const updated = { ...obj, isActive: !obj.isActive };
    handleSave(updated);
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }

  function changeDisabledFilter(includeDisabled: boolean) {
    setShowDisabled(includeDisabled);
    refetch(includeDisabled);
  }

  const filteredComponents = filterData(
    componentTypes || [],
    showDisabled,
    filterText,
  );

  const totalNumberOfPages = Math.ceil(
    filteredComponents.length / ComponentTypesPerPage,
  );

  const startIndex = (currentPage - 1) * ComponentTypesPerPage;
  const endIndex = Math.min(
    startIndex + ComponentTypesPerPage,
    filteredComponents.length,
  );
  const paginatedData = filteredComponents.slice(startIndex, endIndex);

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title="Component Types"
        description="Manage component types"
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Component Type"
        buttonAction={() => setMutateObj(newObj())}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <ComponentTypesFilter
          filterText={filterText}
          setFilterText={setFilterText}
          showDisabled={showDisabled}
          setShowDisabled={() => changeDisabledFilter(!showDisabled)}
        />

        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <ComponentTypesTable
            isLoading={isLoading}
            paginatedData={paginatedData}
            showDisabled={showDisabled}
            statusToggle={toggleActive}
            onEditClicked={(componentType) =>
              setMutateObj({ ...componentType })
            }
          />
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>

      {!!mutateObj && (
        <ComponentTypesModal
          data={mutateObj}
          mode={mutateObj.id > 0 ? 'edit' : 'create'}
          onClose={() => setMutateObj(null)}
          onSave={handleSave}
          loading={isLoading}
        />
      )}
    </Stack>
  );
}
