/* eslint-disable @typescript-eslint/no-explicit-any */
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import componentsApi, { createComponent, updateComponent } from '../../../api/components';
import { hasAdminRole } from '../../../common/roleFunctions';
import { TpgeComponent, TpgeComponentType } from '../../../common/types';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import useAuthStore from '../../../datastore/useAuth';
import useComponents from '../../../datastore/useComponents';
import useComponentTypes from '../../../datastore/useComponentTypes';
import useAlert from '../../../hooks/useAlert';
import useLoadingModal from '../../../hooks/useLoadingModal';
import useRequest from '../../../hooks/useRequest';
import { AdminHeader } from '../components/AdminHeader';
import ComponentModal from './components/ComponentModal';
import { ComponentsFilter } from './components/ComponentsFilter';
import { ComponentsTable } from './components/ComponentsTable';

const ComponentsPerPage = 200;

const filterData = (
  components: TpgeComponent[] = [],
  showDisabled = false,
  filterText = '',
  componentTypeId: number | null = null,
): TpgeComponent[] => {
  const lowerFilter = filterText.toLowerCase();
  const data = components
    .filter(
      (component) =>
        (showDisabled || component.isActive === true) &&
        (filterText.length
          ? component.name.toLowerCase().includes(lowerFilter)
          : true) &&
        ((componentTypeId || 0) > 0
          ? component.componentType.id === componentTypeId
          : true),
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  return data;
};

const newObj = (): TpgeComponent => ({
  id: 0,
  name: '',
  unit: '',
  costPerUnit: 0,
  componentTypeId: 0,
  componentType: {} as TpgeComponentType,
  isActive: true,
  isDefault: false,
});

export default function ComponentsPage() {
  const { currentUser } = useAuthStore();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialComponentTypeFilter = queryParams.get('componentType');
  const initialFilterAsNumber = Number(initialComponentTypeFilter);
  const navigate = useNavigate()
  const {
    components = [],
    loading: isLoadingComponents,
    fetchComponents: refetch,
    showDisabled,
    setShowDisabled,
  } = useComponents();
  const { componentTypes = [], loading: isLoadingComponentTypess } =
    useComponentTypes();
  const { showAlert } = useAlert();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedComponentTypeId, setSelectedComponentTypeId] = useState<
    number | null
  >(!isNaN(initialFilterAsNumber) ? initialFilterAsNumber : null);
  const [currentPage, setCurrentPage] = useState(1);
  const [mutating, setMutating] = useState(false);
  const [mutateObj, setMutateObj] = useState<TpgeComponent | null>(null);
  const [archiveId, setArchiveId] = useState<number | null>(null);
  const { setLoading } = useLoadingModal()

  const { data: activeProductVariants = [], loading: isLoadingActiveProductVariants } = useRequest(() => componentsApi.activeProductVariantsFor(archiveId ?? 0), [archiveId], !archiveId || isNaN(archiveId));

  const isLoading = isLoadingComponents || isLoadingComponentTypess || mutating;

  if (!hasAdminRole(currentUser)) return null;

  async function handleSave(obj: TpgeComponent) {
    const mode = obj.id > 0 ? 'edit' : 'create';
    const mutateFn = mode === 'edit' ? updateComponent : createComponent;
    const errorText = mode === 'edit' ? 'updating' : 'creating';
    const successText = mode === 'edit' ? 'updated' : 'created';
    setMutating(true);
    try {
      const result = await mutateFn(obj);
      if (result.status !== 200) {
        showAlert(
          `Error ${errorText} Component, refresh and try again`,
          'error',
        );
        return;
      } else {
        showAlert(`Component ${successText} successfully`, 'success');
        refetch();
      }
      if (mutateObj) {
        setMutateObj(null);
      }
    } catch (err) {
      showAlert(`Error ${errorText} Component, refresh and try again`, 'error');
    } finally {
      setMutating(false);
    }
  }

  async function onArchiveClicked(id: number) {
    setArchiveId(id);
  }

  function goToArchive() {
    if (archiveId) {
      navigate(`/admin/components/${archiveId}/archive`);
    }
  }

  async function toggleActive(obj: TpgeComponent) {
    const updated = { ...obj, isActive: !obj.isActive };
    handleSave(updated);
  }

  async function archive() {
    if (!archiveId) {
      return
    }
    try {
      setLoading(true)
      await componentsApi.archive(archiveId);
      refetch();
      setArchiveId(null);
      showAlert('Component archived successfully', 'success')
    } catch (err: any) {
      console.error(err)
      showAlert(err.data ?? err.message ?? 'Error archiving component', 'error')
    } finally {
      setLoading(false)
    }
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }
  const filteredComponents = filterData(
    components || [],
    showDisabled,
    filterText,
    selectedComponentTypeId,
  );

  const totalNumberOfPages = Math.ceil(
    filteredComponents.length / ComponentsPerPage,
  );

  const startIndex = (currentPage - 1) * ComponentsPerPage;
  const endIndex = Math.min(
    startIndex + ComponentsPerPage,
    filteredComponents.length,
  );
  const paginatedData = filteredComponents.slice(startIndex, endIndex);

  const archiveComponent = paginatedData.find((f) => f.id === archiveId);

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title="Components"
        description="Manage components"
        icon={<ShoppingCartIcon fontSize="large" sx={{ color: 'white' }} />}
        buttonText="New Component"
        buttonAction={() => setMutateObj(newObj())}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <ComponentsFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedComponentTypeId={selectedComponentTypeId}
          setSelectedComponentTypeId={(val = '') => {
            const parsed = parseInt(val);
            if (parsed && !isNaN(parsed)) {
              setSelectedComponentTypeId(parsed);
            } else {
              setSelectedComponentTypeId(null);
            }
            setCurrentPage(1);
          }}
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          componentTypes={componentTypes || []}
        />
        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <ComponentsTable
            isLoading={isLoading}
            paginatedData={paginatedData}
            showDisabled={showDisabled}
            statusToggle={toggleActive}
            editComponent={(component) => setMutateObj(component)}
            onArchiveClicked={(component) => onArchiveClicked(component.id)}
          />
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>
      {!!mutateObj && (
        <ComponentModal
          data={mutateObj}
          componentTypes={componentTypes || []}
          mode={mutateObj.id > 0 ? 'edit' : 'create'}
          onClose={() => setMutateObj(null)}
          onSave={handleSave}
          loading={isLoading}
        />
      )}
      {!!archiveComponent && !isLoadingActiveProductVariants && (activeProductVariants || []).length > 0 && (
        <ConfirmModal
          title={`Archive ${archiveComponent.name}?`}
          content={`${archiveComponent.name} has ${activeProductVariants?.length} active product variants. You need to review them first`}
          onClose={() => setArchiveId(null)}
          onConfirm={goToArchive}
          confirmButtonText='Review'
        />
      )
      }
      {!!archiveComponent && !isLoadingActiveProductVariants && (activeProductVariants || []).length === 0 && (
        <ConfirmModal
          title={`Archive ${archiveComponent.name}`}
          content={`Are you sure you want to PERMANENTLY ARCHIVE ${archiveComponent.name}?`}
          onClose={() => setArchiveId(null)}
          onConfirm={archive}
        />
      )}
    </Stack>
  );
}
