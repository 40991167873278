 
import { add } from 'date-fns/add';
import { isBefore } from 'date-fns/isBefore';
import { PropsWithChildren } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import { StaffOrder } from '../../../../common/types';
import useOrders from '../../../../datastore/useOrders';
import usePlayAlarmSound from '../../../../hooks/usePlayAlarmSound';
import OrderQueue from '../../components/OrderQueue';
import StationHeader from '../../components/StationHeader';
import {
  anyFoodPending
} from '../../functions/stationFunctions';

interface FoodStationProps {
  stationId: number
}

const hasPendingFood = (orders: StaffOrder[]): boolean => {
  const nowPlusFiveMinutes = add(new Date(), { minutes: 5 });
  let shouldRingAlarm = false;

  for (const order of orders) {
    if (shouldRingAlarm) {
      break;
    }
    if (anyFoodPending(order.orderItems)) {
      // Check if the requested pickup time is within 5 minutes of the current time or in the past
      if (order.requestedPickupTime) {
        const pickupTime = new Date(order.requestedPickupTime);
        shouldRingAlarm = isBefore(pickupTime, nowPlusFiveMinutes)
      }
    }
  }
  return shouldRingAlarm;
}

export default function FoodStation({ stationId, children }: PropsWithChildren<FoodStationProps>) {
  const { orders } = useOrders()
  usePlayAlarmSound(hasPendingFood(orders || []), 3000);

  return (
    <OrderQueue
      categories={[ProductCategoryType.Food]}
      stationId={stationId}
      header={() => <StationHeader />}
    >
      {children}
    </OrderQueue>
  )
}
