
import { PropsWithChildren } from 'react';
import { ProductCategoryType } from '../../../../common/enums';
import OrderQueue from '../../components/OrderQueue';
import StationHeader from '../../components/StationHeader';

interface ColdDrinkStationProps {
  stationId: number
}

export default function ColdDrinksStation({
  stationId,
  children
}: PropsWithChildren<ColdDrinkStationProps>) {

  return (
    <OrderQueue
      categories={[ProductCategoryType.ColdDrinks]}
      stationId={stationId}
      header={() => <StationHeader />}
    >
      {children}
    </OrderQueue>
  )
}
