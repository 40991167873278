import { create } from 'zustand';
import stationsApi from '../api/station';
import { Station } from '../common/types';
import useAuthStore from './useAuth';

type StationsStore = {
  hasLoaded: boolean;
  stations: Station[];
  loading: boolean;
  error: unknown | null;
  fetchStations: () => Promise<void>;
};

const useStations = create<StationsStore>((set, get) => ({
  hasLoaded: false,
  stations: [],
  loading: false,
  error: null,
  fetchStations: async () => {
    const currentUser = useAuthStore.getState().currentUser

    if (!currentUser) {
      set({ stations: [], hasLoaded: false });
      return;
    }

    if (get().loading) {
      return;
    }

    try {
      set({ loading: true, hasLoaded: true });
      const result = await stationsApi.getAll();
      set({
        stations: Array.isArray(result.data) ? result.data : [],
        loading: false,
        error: null,
      });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },
}));

// Subscribe to changes in the logged in user
useAuthStore.subscribe((state) => {
  const currentUser = state.currentUser;
  if (currentUser) {
    useStations.getState().fetchStations();
  } else {
    useStations.setState({ stations: [], hasLoaded: false });
  }
});

// Initialize stations fetch when the store is created
useStations.getState().fetchStations();

export default useStations;
