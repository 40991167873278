import ChevronLeft from '@mui/icons-material/ChevronLeft';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ClockIcon } from '@mui/x-date-pickers';
import { format } from 'date-fns/format';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderSummary } from '../../../api/customerOrders';
import {
  OrderSummary,
  SummaryOrderItem,
  SummaryOrderItemProductVariant,
} from '../../../common/types';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import useRequest from '../../../hooks/useRequest';

const variantSort = (
  a: SummaryOrderItemProductVariant,
  b: SummaryOrderItemProductVariant,
): number => {
  return a.variantName.localeCompare(b.variantName);
};

const itemKey = (item: SummaryOrderItem) => {
  const chunks = [item.productName, ...item.items.sort(variantSort).map(v => `${v.variantName}${v.quantity}`)]
  return chunks.join(', ');
}

const itemName = (item: SummaryOrderItem) => {
  const chunks = [item.productName]
  if (item.hasSizes) {
    chunks.push(`(${item.sizeName})`)
  }
  return chunks.join(' ')
}

const PricingBreakdown = ({ title, value, fontWeight = 'normal' }: { title: string, value: number, fontWeight?: 'bold' | 'normal' }) => {
  return (
    <Stack flexDirection="row">
      <Typography
        variant="h4"
        fontWeight={fontWeight}
        sx={{ flex: 2, textAlign: 'right' }}
      >
        {title}:
      </Typography>
      <Typography variant="h4" fontWeight={fontWeight} sx={{ flex: 1, textAlign: 'right' }}>
        ${value.toFixed(2)}
      </Typography>
    </Stack>
  )
}


export default function OrderConfirmed() {
  const navigate = useNavigate()
  const theme = useTheme();
  const { orderId } = useParams();
  const { data, loading } = useRequest<OrderSummary>(
    () => getOrderSummary(+orderId!),
    [orderId],
  );

  const formattedPickup = data?.requestedPickupTime
    ? format(data.requestedPickupTime, 'do LLL yyyy HH:mm')
    : '';
  return (
    <>
      <LoadingSpinner isLoading={loading}>
        <Stack sx={{ flex: 0, paddingX: '1rem', paddingTop: '1rem' }}>
          <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
            <IconButton size='large' onClick={() => navigate('/', { replace: true })}><ChevronLeft /></IconButton>
            {
              data ? (
                <Typography
                  variant="h3"
                  sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: theme.palette.common.white,
                  }}
                >
                  Order confirmed
                </Typography>
              ) : (
                <Typography variant="h3">Something went wrong :(</Typography>
              )
            }
          </Stack>
          {data ? (
            <>
              <Divider sx={{ marginY: 1 }} />
              <Typography variant="h5">Pick up:</Typography>
              <Typography
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                }}
              >
                <LocationOnOutlined sx={{ marginRight: '1rem' }} />
                {data.siteName}
              </Typography>
              <Typography
                sx={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  display: 'flex',
                }}
              >
                <ClockIcon sx={{ marginRight: '1rem' }} />
                {formattedPickup}
              </Typography>
              {data.items.map((orderItem) => (
                <Stack key={itemKey(orderItem)}>
                  <Divider sx={{ marginY: 1 }} />
                  <Stack flexDirection="row" justifyContent="flex-start">
                    <Typography fontWeight="bold">
                      {itemName(orderItem)}
                    </Typography>
                    <Typography fontWeight="bold" sx={{ marginLeft: 'auto' }}>
                      ${orderItem.total.toFixed(2)}
                    </Typography>
                  </Stack>
                  {
                    orderItem.noMilk && (
                      <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                      >
                        <Typography fontSize="12px">
                          - No Milk
                        </Typography>
                        <Typography fontSize="12px" sx={{ marginLeft: 'auto' }}>
                          $0.00
                        </Typography>
                      </Stack>
                    )
                  }
                  {orderItem.items.filter(f => !f.isCore).sort(variantSort).map((variant) => (
                    <Stack
                      key={`${itemKey(orderItem)}_${variant.variantName}`}
                      flexDirection="row"
                      justifyContent="flex-start"
                    >
                      <Typography fontSize="12px">
                        - {variant.variantName} x {variant.quantity}
                      </Typography>
                      <Typography fontSize="12px" sx={{ marginLeft: 'auto' }}>
                        ${variant.total.toFixed(2)}
                      </Typography>
                    </Stack>
                  ))}
                  {orderItem.notes?.length > 0 && (
                    <Typography
                      fontSize="12px"
                      sx={{ flex: 1, textAlign: 'left', marginTop: 1 }}
                    >
                      * {orderItem.notes}
                    </Typography>
                  )}
                  {orderItem.quantity > 1 && (
                    <Stack flexDirection="row" justifyContent="space">
                      <Typography sx={{ flex: 2, textAlign: 'right' }}>
                        x {orderItem.quantity}
                      </Typography>
                      <Typography sx={{ flex: 1, textAlign: 'right' }}>
                        ${(orderItem.total * orderItem.quantity).toFixed(2)}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              ))}
              <Divider sx={{ marginY: 1 }} />
              <PricingBreakdown title="Subtotal" value={data.subtotal} />
              <PricingBreakdown title="GST" value={data.gst} />
              <PricingBreakdown title="Order Total" value={data.total} fontWeight="bold" />
              {
                data.hasSurcharge ? (
                  <>
                    <Divider sx={{ marginY: 1 }} />
                    <PricingBreakdown title="Surcharge" value={data.surcharge} />
                    <PricingBreakdown title="Total" value={data.totalPlusSurcharge} fontWeight="bold" />
                  </>
                ) : null
              }
            </>
          ) : null}
        </Stack>
      </LoadingSpinner>
    </>
  );
}
