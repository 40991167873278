import RuleIcon from '@mui/icons-material/Rule';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { OrderItemStatus, ProductCategoryType } from '../../../../common/enums';
import { isItemType } from '../../../../common/orderFunctions';
import { StaffOrderItem } from '../../../../common/types';
import useOrders from '../../../../datastore/useOrders';
import { IndicatorBox } from '../../components/IndicatorBox';
import PaidStatusIcon from '../../components/PaidStatusIcon';
import { allCategoryItemsAreStatus, allColdDrinksFinished, allColdDrinksPlaced, allColdDrinksStarted, allFoodFinished, allFoodPending, allFoodStarted, allHotCoffeeOrTeaFinished, allHotCoffeeOrTeaStarted, allOrderItemsFinished } from '../../functions/stationFunctions';

interface Props {
    categories: ProductCategoryType[]
    stationId: number
}
export default function PartiallyCompleteOrders({
    categories = [],
    stationId = 0
}: Props) {
    const theme = useTheme()
    const { orders } = useOrders()

    const outstandingOrders = orders.filter(order =>
        order.orderItems.find(item => isItemType(item, categories) && item.stationId === stationId)
        && allCategoryItemsAreStatus(order.orderItems, categories, OrderItemStatus.Finished)
        && !allOrderItemsFinished(order.orderItems)
    ).map(order => ({
        ...order,
        requestedPickupTime: order.requestedPickupTime
            ? new Date(order.requestedPickupTime)
            : null,
        startTime: order.startTime ? new Date(order.startTime) : null,
        finishTime: order.finishTime ? new Date(order.finishTime) : null,
    }))

    if (outstandingOrders.length === 0) {
        return null
    }

    return (
        <>
            <Grid container sx={{ width: '100%' }}>
                {outstandingOrders.map(order => (
                    <Grid size={{ xs: 12 }} sx={{ width: '100%' }} key={order.id}>
                        <Paper
                            elevation={8}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                padding: 0,
                                backgroundColor: theme.palette.common.white,
                                color: theme.palette.common.deepblue,

                            }}
                        >
                            <Stack
                                sx={{
                                    backgroundColor: theme.palette.common.grey,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: 0.25,
                                }}
                            >
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        padding: 0,
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Stack
                                            sx={{ padding: 1, alignItems: 'center' }}
                                        >
                                            <RuleIcon />
                                        </Stack>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography
                                            variant="h3"
                                            sx={{ marginLeft: theme.spacing(1) }}
                                        >
                                            {order.customerName}
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" sx={{ paddingRight: 2 }}>
                                        <PaidStatusIcon isPaid={!!order.isPaid} />
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.HotCoffee ||
                                                item.productCategoryType ===
                                                ProductCategoryType.Tea,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Hot Coffee"
                                                    allStartedFn={allHotCoffeeOrTeaStarted}
                                                    allFinishedFn={allHotCoffeeOrTeaFinished}
                                                    allPendingFn={allHotCoffeeOrTeaFinished}
                                                />
                                            )}
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.ColdDrinks,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Cold Drinks"
                                                    allStartedFn={allColdDrinksStarted}
                                                    allFinishedFn={allColdDrinksFinished}
                                                    allPendingFn={allColdDrinksPlaced}
                                                />
                                            )}
                                        {order.orderItems.some(
                                            (item: StaffOrderItem) =>
                                                item.productCategoryType ===
                                                ProductCategoryType.Food,
                                        ) && (
                                                <IndicatorBox
                                                    orderItems={order.orderItems}
                                                    label="Food"
                                                    allStartedFn={allFoodStarted}
                                                    allFinishedFn={allFoodFinished}
                                                    allPendingFn={allFoodPending}
                                                />
                                            )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Divider />
        </>
    )
}