import Decimal from 'decimal.js';


export const calculateTotalPlusSurcharge = (orderTotal: Decimal, fixed: number | undefined, percentage: number | undefined): Decimal | null => {
    if (fixed !== undefined && percentage !== undefined) {
        const numerator = orderTotal.plus(new Decimal(fixed));
        const denominator = new Decimal(1).minus(new Decimal(percentage).dividedBy(100));
        const calculated = numerator.dividedBy(denominator);

        return calculated.times(100).ceil().dividedBy(100);
    }
    return null;
}