/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { InternalAxiosRequestConfig } from 'axios';
import { apiUrl } from '../common/constants';
import LocalStorageSvc, { JWT_KEY, persistUser, REFRESH_TOKEN_KEY, USER_DATA_KEY, UserType } from '../common/localStorageSvc';
import { RefreshTokenEndpoint } from '../common/endpoints';

const isExpired = (token: string): boolean => {
  const decoded = JSON.parse(atob(token.split('.')[1]));
  return decoded.exp < Date.now() / 1000
}

const refreshTokenRequest = () => {
  const refreshToken = LocalStorageSvc.load<string>(REFRESH_TOKEN_KEY)
  console.log('refreshTokenRequest', refreshToken)
  return fetch(`${apiUrl}${RefreshTokenEndpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      RefreshToken: refreshToken
    }),
  })
}

axios.interceptors.request.use(
  async function (
    request: InternalAxiosRequestConfig<any>,
  ): Promise<InternalAxiosRequestConfig<any>> {
    let jwt = LocalStorageSvc.load<string>(JWT_KEY);
    if (jwt) {
      if (isExpired(jwt)) {
        const response = await refreshTokenRequest();
        const data = await response.json();
        const user = LocalStorageSvc.load<UserType | null>(USER_DATA_KEY);
        if (user && data?.token && data?.refreshToken) {
          user.jwtToken = data.token;
          user.refreshToken = data.refreshToken;
          jwt = user.jwtToken
          persistUser(user);
        }
      }
      request.headers.set('Authorization', `Bearer ${jwt}`);
    }
    request.headers.set('Content-Type', 'application/json');
    return request;
  },
  function (error: any): any | null | undefined {
    console.error(error);
  },
);

export async function getRequest(endpoint: string) {
  try {
    const url = `${apiUrl}${endpoint}`;
    const response = await axios.get(url);
    return response;
  } catch (error: any) {
    console.error(error);
    throw error.response
  }
}

export async function postRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.post(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function putRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.put(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function deleteRequest(endpoint: string) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.delete(url);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}

export async function patchRequest(endpoint: string, data: any) {
  try {
    const url = `${apiUrl}${endpoint}`;
    return await axios.patch(url, data);
  } catch (error: any) {
    console.error(error.response);
    throw error.response
  }
}
