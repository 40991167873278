import { create } from 'zustand';
import api from '../api/surcharge';
import { Surcharge } from '../common/types';

type SurchargeStore = {
    hasLoaded: boolean;
    surcharge: Surcharge | null;
    loading: boolean;
    error: unknown | null;
    fetch: () => Promise<void>;
};

const useSurcharge = create<SurchargeStore>((set, get) => ({
    hasLoaded: false,
    surcharge: null,
    loading: false,
    error: null,
    fetch: async () => {
        if (get().loading) {
            return;
        }

        try {
            set({ loading: true, hasLoaded: true });
            const result = await api.get();
            set({ surcharge: result.data, loading: false, error: null });
        } catch (err) {
            console.error(err);
            set({ loading: false, error: err });
        }
    },
}));

useSurcharge.getState().fetch();

export default useSurcharge;