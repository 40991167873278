export const PasswordResetEndpoint = 'auth/reset-password';
export const ForgotPasswordEndpoint = 'auth/forgot-password';
export const CustomerRegisterEndpoint = 'auth/register';
export const LoginEndpoint = 'auth/login';
export const LogoutEndpoint = 'auth/logout';
export const RefreshTokenEndpoint = 'auth/refresh';
export const CustomerProfileEndpoint = 'customer/profile';
export const CustomerOrdersEndpoint = 'customer/orders';
export const CustomerEndpoint = 'customer';
export const StaffAuthEndpoint = 'auth';
export const StaffEndpoint = 'staff';
export const TpgeComponentEndpoint = 'component';
export const TpgeComponentTypeEndpoint = 'componenttype';
export const OrderEndpoint = 'order';
export const PopularProductEndpoint = 'popularproduct';
export const SiteEndpoint = 'site';
export const ProductVariantEndpoint = 'productvariant';
export const ReportEndpoint = 'report';
export const StationEndpoint = 'station';
export const ProductEndpoint = 'product';
export const SurchargeEndpoint = 'surcharge';
