import { OrderEndpoint } from '../common/endpoints';
import { Order, OrderSummary, ServerResponse, StaffOrder } from '../common/types';
import { getRequest, patchRequest, postRequest } from './requests';

type UpdateOrderStation = {
  orderId: number;
  newStationId: number;
  productCategories: string[];
};

type ChangeQueueOrderOrderItem = {
  id: number;
  queueOrder: number | null;
};

type ChangeQueueOrderOrder = {
  id: number;
  orderItems: ChangeQueueOrderOrderItem[];
};

export type ChangeQueueOrders = {
  orderDtos: ChangeQueueOrderOrder[];
  productCategories: string[];
};

type StartStopFinishOrderOrderItem = {
  id: number;
  status: string;
};

export type GetOrdersParams = {
  includeHistory?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
};

type StartStopFinishOrder = {
  id: number;
  startTime: Date | null;
  finishTime: Date | null;
  paymentType: string | null;
  softDelete: boolean;
  orderItems: StartStopFinishOrderOrderItem[];
};

async function create(order: Order) {
  return await postRequest(OrderEndpoint, order);
}

async function getOrders(params: GetOrdersParams = { includeHistory: false }) {
  const queryParams = new URLSearchParams();

  if (params.includeHistory) {
    queryParams.append('includeHistory', 'true');
  }
  if (params.startDate) {
    queryParams.append('startDate', params.startDate.toISOString());
  }
  if (params.endDate) {
    queryParams.append('endDate', params.endDate.toISOString());
  }

  const queryString = queryParams.toString();
  const result: ServerResponse<StaffOrder[]> = await getRequest(
    `${OrderEndpoint}${queryString ? `?${queryString}` : ''}`,
  );
  return result;
}

async function startStopFinishOrder(payload: StartStopFinishOrder) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/${payload.id}/startstopfinish`,
    payload,
  );
  return result;
}

async function changeQueueOrders(payload: ChangeQueueOrders) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/queues`,
    payload,
  );
  return result;
}

async function updateStation(payload: UpdateOrderStation) {
  const result: ServerResponse<null> = await patchRequest(
    `${OrderEndpoint}/${payload.orderId}/station`,
    payload,
  );
  return result;
}

async function getById(id: number) {
  const result: ServerResponse<OrderSummary> = await getRequest(
    `${OrderEndpoint}/${id}`,
  );
  return result;
}

async function sendReceipt(id: number, email: string) {
  const result: ServerResponse<StaffOrder> = await postRequest(`${OrderEndpoint}/${id}/receipt`, { email })
  return result
}

export default {
  create,
  getOrders,
  getById,
  startStopFinishOrder,
  changeQueueOrders,
  updateStation,
  sendReceipt
};
