import { Product } from './types';

const customOrder = ['cappucino','cappuccino', 'flat white', 'latte'];

export function sortProducts(products: Product[]): Product[] {
  return [...products].sort((a, b) => {
    const indexA = customOrder.indexOf(a.name.toLowerCase());
    const indexB = customOrder.indexOf(b.name.toLowerCase());

    // If both products are in the custom order list
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only first product is in custom order list, it comes first
    if (indexA !== -1) return -1;

    // If only second product is in custom order list, it comes first
    if (indexB !== -1) return 1;

    // If neither product is in custom order list, sort alphabetically
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });
}
