import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ProductCategoryType, Stations } from '../../../common/enums';
import { isAdmin, isStaff } from '../../../common/typeAssertionFunctions';
import InfoCard from '../../../components/card/InfoCard';
import useAuthStore from '../../../datastore/useAuth';
import useOrders from '../../../datastore/useOrders';
import useProducts from '../../../datastore/useProducts';
import useStations from '../../../datastore/useStations';
import ColdDrinksStation from './components/ColdDrinksStation';
import FoodStation from './components/FoodStation';
import OpposingStation from './components/OpposingStation';
import PartiallyCompleteOrders from './components/PartiallyCompleteOrders';
import PrimaryStation from './components/PrimaryStation';


export default function StaffHomePage() {
  const { currentUser } = useAuthStore();
  const theme = useTheme();
  const { startPolling, stopPolling } = useOrders();
  const { products = [], loading: productsLoading } = useProducts();
  const { stations = [] } = useStations();

  useEffect(() => {
    startPolling({ initialInterval: 5000, maxInterval: 15000 });
    return () => {
      stopPolling();
    };
  }, []);

  if (!isStaff(currentUser)) return null;

  const station = stations?.find((station) => station.name === currentUser.stationName)
  if (!station) {
    return (
      <Link to="/staff/settings" style={{ textDecoration: 'none' }}>
        <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
          <InfoCard
            Icon={ErrorIcon}
            title="No Station Selected"
            subtitle="Click here to go to settings and select a station."
          />
        </Stack>
      </Link>
    )
  }

  if (!products.length && !productsLoading) {
    if (isAdmin(currentUser)) {
      return (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.common.white,
            height: '100vh',
          }}
        >
          <Link to="/admin/products" style={{ textDecoration: 'none' }}>
            <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
              <InfoCard
                Icon={ErrorIcon}
                title="No Products"
                subtitle="Start adding Products"
              />
            </Stack>
          </Link>
        </Grid>
      )
    } else {
      <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
        <InfoCard
          Icon={ErrorIcon}
          title="No Products"
          subtitle="Someone needs to add Products!"
        />
      </Stack>

    }
  }

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >

      {currentUser.stationName === Stations.StationOne ||
        currentUser.stationName === Stations.StationTwo ? (
        <>
          <PrimaryStation stationId={station.id}>
            <PartiallyCompleteOrders stationId={station.id} categories={[ProductCategoryType.HotCoffee, ProductCategoryType.Tea]} />
          </PrimaryStation>
          <OpposingStation />
        </>
      ) : currentUser.stationName === Stations.ColdDrinks ? (
        <ColdDrinksStation stationId={station.id}>
          <PartiallyCompleteOrders stationId={station.id} categories={[ProductCategoryType.ColdDrinks]} />
        </ColdDrinksStation>
      ) : currentUser.stationName === Stations.Food ? (
        <FoodStation stationId={station.id}>
          <PartiallyCompleteOrders stationId={station.id} categories={[ProductCategoryType.Food]} />
        </FoodStation>
      ) : (
        <Link to="/staff/settings" style={{ textDecoration: 'none' }}>
          <Stack sx={{ flexDirection: 'column', cursor: 'pointer' }}>
            <InfoCard
              Icon={ErrorIcon}
              title="No Station Selected"
              subtitle="Click here to go to settings and select a station."
            />
          </Stack>
        </Link>
      )}
    </Grid>
  );
}
