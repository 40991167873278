 
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ProductCategoryType, Stations } from '../../../common/enums';
import { groupStaffOrderItems } from '../../../common/orderFunctions';
import { StaffOrder, Station } from '../../../common/types';
import useOrders from '../../../datastore/useOrders';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import useAlert from '../../../hooks/useAlert';
import useStations from '../../../datastore/useStations';
import { TimeIndicator } from './TimeIndicator';

interface DraggableStationCardProps {
  isAllStarted: boolean;
  stationId: number;
  order: StaffOrder;
}

export default function DraggableStationCard({
  isAllStarted: isAllHotCoffeeStarted,
  order,
  stationId,
}: DraggableStationCardProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showAlert } = useAlert()
  const { isUpdatingOrder: isLoading, moveOrder, fetchOrders } = useOrders()
  const { stations = [] } = useStations();
  const [moveOrderId, setMoveOrderId] = useState<number | null>(null);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };


  const filteredOrderItems = order.orderItems.filter(
    (orderItem) =>
      orderItem.stationId === stationId &&
      [ProductCategoryType.HotCoffee, ProductCategoryType.Tea].includes(
        orderItem.productCategoryType as ProductCategoryType,
      ),
  );
  const groupedItems = groupStaffOrderItems(filteredOrderItems, true);

  const thisStation = stations.find((station) => station.id === stationId);
  let opposingStation: Station | undefined = undefined;
  if (thisStation) {
    const isStationOne = thisStation.name === Stations.StationOne;
    opposingStation = isStationOne
      ? stations.find((station) => station.name === Stations.StationTwo)
      : stations.find((station) => station.name === Stations.StationOne);
  }

  const  handlePayNow = (order: StaffOrder) => {
    navigate('/staff/pos', {
      state: {
        order: order,
        returnRoute: '/staff/walkup',
      },
    });
  }

  const cancelMoveOrder = () => {
    setMoveOrderId(null);
  }

  const onMoveOrderConfirmed = async () => {
    if (!moveOrderId) {
      console.error('Failed to move order due to invalid order id');
      showAlert('Failed to move order, please refresh and try again.', 'error');
      return;
    }
    if (!opposingStation?.id) {
      showAlert('Failed to move order, couldnt apply station logic.', 'error');
      return
    }

    try {
      await moveOrder(moveOrderId, opposingStation.id)
      fetchOrders()
      setMoveOrderId(null);
      showAlert('Order moved', 'success');
    } catch(err) {
      showAlert('Failed to move order, please refresh and try again.', 'error');
    }
  }

  return (
    <>
      <Stack ref={setNodeRef} {...attributes} {...listeners} style={style}>
        <Stack
          sx={{
            backgroundColor: theme.palette.common.grey,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Stack sx={{ padding: 1, alignItems: 'center' }}>
              {isAllHotCoffeeStarted ? <LockIcon /> : <DragHandleIcon />}
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Typography variant="h3" sx={{ marginLeft: theme.spacing(1) }}>
              {order.customerName}
            </Typography>
          </Stack>
          <TimeIndicator time={order.requestedPickupTime} />
        </Stack>
        <TableContainer component={Paper}>
          <Table aria-label="order table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Qty</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupedItems.map((orderItem, index: number) => {
                if (
                  orderItem.productCategoryType !==
                  ProductCategoryType.HotCoffee &&
                  orderItem.productCategoryType !== ProductCategoryType.Tea
                ) {
                  return null;
                }
                return (
                  <TableRow key={index}>
                    <TableCell>{orderItem.quantity}</TableCell>
                    <TableCell>{orderItem.productName}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Stack
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flex: 1,
            }}
          >
            <Stack sx={{ flexDirection: 'row' }}>
              <Button
                variant="outlined"
                size="small"
                color="warning"
                sx={{
                  margin: 1,
                  width: '10em',
                  whiteSpace: 'nowrap',
                }}
                disabled={isLoading || isAllHotCoffeeStarted}
                onClick={() => setMoveOrderId(order.id)}
              >
                Move Station
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{
                  margin: 1,
                  whiteSpace: 'nowrap',
                }}
                disabled={isLoading || order.isPaid}
                onClick={() => {
                  handlePayNow(order);
                }}
              >
                Pay Now
              </Button>
            </Stack>
          </Stack>
        </TableContainer>
      </Stack>
      {moveOrderId && (
        <ConfirmModal
          onClose={cancelMoveOrder}
          title="Move Order"
          content="Are you sure you want to move this order?"
          onConfirm={onMoveOrderConfirmed}
        />
      )}
    </>
  );
}
