import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns/format';
import { useNavigate, useParams } from 'react-router-dom';
import ordersApi from '../../../api/order';
import { SummaryOrderItem, SummaryOrderItemProductVariant } from '../../../common/types';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import useRequest from '../../../hooks/useRequest';
import { AdminHeader } from '../../Admin/components/AdminHeader';

const variantSort = (
  a: SummaryOrderItemProductVariant,
  b: SummaryOrderItemProductVariant,
): number => {
  return a.variantName.localeCompare(b.variantName);
};

const itemKey = (item: SummaryOrderItem) => {
  const chunks = [item.productName, ...item.items.sort(variantSort).map(v => `${v.variantName}${v.quantity}`)]
  return chunks.join(', ');
}

const itemName = (item: SummaryOrderItem) => {
  const chunks = [item.productName]
  if (item.hasSizes) {
    chunks.push(`(${item.sizeName})`)
  }
  return chunks.join(' ')
}

const PricingBreakdown = ({ title, value, fontWeight = 'normal' }: { title: string, value: number, fontWeight?: 'bold' | 'normal' }) => {
  return (
    <Stack flexDirection="row">
      <Typography
        variant="h4"
        fontWeight={fontWeight}
        sx={{ flex: 3, textAlign: 'right' }}
      >
        {title}:
      </Typography>
      <Typography variant="h4" fontWeight={fontWeight} sx={{ flex: 1, textAlign: 'right' }}>
        ${value.toFixed(2)}
      </Typography>
    </Stack>
  )
}

export default function OrderDetailsPage() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { data: order, loading } = useRequest(() => ordersApi.getById(Number(orderId)), [orderId], isNaN(Number(orderId)));

  if (loading) {
    return <LoadingSpinner isLoading={loading} />;
  }

  if (!order || !order.items) {
    return (
      <Paper sx={{ p: 4, m: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {!order ? ' Order not found' : 'Order contains no items'}
          </Typography>
          <Button variant="contained" onClick={() => navigate('/staff/pos')}>
            Back to POS
          </Button>
        </Box>
      </Paper>
    );
  }

  return (
    <>
      <AdminHeader
        title={`Order #${orderId}`}
        description="Order Details"
        showBackButton={true}
        onBackButtonClick={() => navigate('/staff/pos')}
      />
      <Stack sx={{ marginLeft: 4, marginRight: 4, marginTop: 2 }}>
        <Box
          sx={{
            p: 3,
            backgroundColor: 'background.paper',
            borderRadius: 1,
            mb: 3,
          }}
        >
          <Stack direction="row" spacing={4}>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Customer Name
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.customerName}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Payment Status
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.isPaid ? 'Paid' : 'Not Paid'}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Payment Type
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.paymentType || 'Not Paid'}
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row" spacing={4} sx={{ mt: 3 }}>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Created Time
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.createdAt
                  ? format(new Date(order.createdAt), 'dd/MM/yy HH:mm a')
                  : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Start Time
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.startTime
                  ? format(new Date(order.startTime), 'dd/MM/yy HH:mm a')
                  : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontSize: '0.875rem', fontWeight: 600, mb: 1 }}
              >
                Finish Time
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {order.finishTime
                  ? format(new Date(order.finishTime), 'dd/MM/yy HH:mm a')
                  : '-'}
              </Typography>
            </Box>
          </Stack>
        </Box>
        {order.items.map((orderItem) => (
          <Stack key={itemKey(orderItem)}>
            <Divider sx={{ marginY: 1 }} />
            <Stack flexDirection="row" justifyContent="flex-start">
              <Typography fontWeight="bold">
                {itemName(orderItem)}
              </Typography>
              <Typography fontWeight="bold" sx={{ marginLeft: 'auto' }}>
                ${orderItem.total.toFixed(2)}
              </Typography>
            </Stack>
            {
              orderItem.noMilk && (
                <Stack
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <Typography fontSize="12px">
                    - No Milk
                  </Typography>
                  <Typography fontSize="12px" sx={{ marginLeft: 'auto' }}>
                    $0.00
                  </Typography>
                </Stack>
              )
            }
            {orderItem.items.filter(f => !f.isCore).sort(variantSort).map((variant) => (
              <Stack
                key={`${itemKey(orderItem)}_${variant.variantName}`}
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Typography fontSize="12px">
                  - {variant.variantName} x {variant.quantity}
                </Typography>
                <Typography fontSize="12px" sx={{ marginLeft: 'auto' }}>
                  ${variant.total.toFixed(2)}
                </Typography>
              </Stack>
            ))}
            {orderItem.notes?.length > 0 && (
              <Typography
                fontSize="12px"
                sx={{ flex: 1, textAlign: 'left', marginTop: 1 }}
              >
                * {orderItem.notes}
              </Typography>
            )}
            {orderItem.quantity > 1 && (
              <Stack flexDirection="row" justifyContent="space">
                <Typography sx={{ flex: 1, textAlign: 'center' }}>
                  x {orderItem.quantity}
                </Typography>
                <Typography>
                  ${(orderItem.total * orderItem.quantity).toFixed(2)}
                </Typography>
              </Stack>
            )}
          </Stack>
        ))}
        <Divider sx={{ marginY: 1 }} />
        <PricingBreakdown
          title="Subtotal"
          value={order.subtotal}
        />
        <PricingBreakdown
          title="GST"
          value={order.gst}
        />
        <PricingBreakdown
          title="Order Total"
          value={order.total}
          fontWeight='bold'
        />
        {
          order.hasSurcharge ? (
            <>
              <Divider sx={{ marginY: 1 }} />
              <PricingBreakdown
                title="Surcharge"
                value={order.surcharge}
              />
              <PricingBreakdown
                title="Total"
                value={order.totalPlusSurcharge}
                fontWeight='bold'
              />
            </>
          ) : null
        }
      </Stack>
    </>
  );
}
