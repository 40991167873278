import { CustomerOrdersEndpoint } from '../common/endpoints';
import { CustomerOrder, OrderSummary, ServerResponse } from '../common/types';
import { getRequest, postRequest, putRequest } from './requests';

interface PaymentIntentResponse {
  client_secret: string;
}

export async function createStripePaymentIntent(
  amount: number,
): Promise<ServerResponse<PaymentIntentResponse>> {
  const res: ServerResponse<PaymentIntentResponse> = await postRequest(
    `${CustomerOrdersEndpoint}/createintent`,
    {
      amount,
    },
  );
  return res;
}

export async function createOrUpdateOrder(
  order: CustomerOrder,
): Promise<ServerResponse<CustomerOrder>> {
  const res: ServerResponse<CustomerOrder> = await putRequest(
    CustomerOrdersEndpoint,
    order,
  );
  return res;
}

export async function confirmDeferredOrder(
  orderId: number,
): Promise<ServerResponse<void>> {
  const res: ServerResponse<void> = await postRequest(
    `${CustomerOrdersEndpoint}/${orderId}/confirm-deferred`,
    {},
  );
  return res;
}

export async function completeOrder(
  orderId: number,
  paymentIntentId: string,
): Promise<ServerResponse<void>> {
  const res: ServerResponse<void> = await postRequest(
    `${CustomerOrdersEndpoint}/${orderId}/complete/${paymentIntentId}`,
    {},
  );
  return res;
}

export async function getOrderSummary(
  orderId: number,
): Promise<ServerResponse<OrderSummary>> {
  const res: ServerResponse<OrderSummary> = await getRequest(
    `${CustomerOrdersEndpoint}/${orderId}/summary`,
  );
  return res;
}

export async function getOrderHistory(): Promise<
  ServerResponse<OrderSummary[]>
> {
  const res: ServerResponse<OrderSummary[]> = await getRequest(
    `${CustomerOrdersEndpoint}/history`,
  );
  return res;
}

export default {
  createStripePaymentIntent,
  createOrUpdateOrder,
  completeOrder,
  getOrderSummary,
  getOrderHistory,
};
