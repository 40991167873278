import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import SideNavbar from '../../../components/navbar/SideNavbar';
import useAuthStore from '../../../datastore/useAuth';
import { isStaff } from '../../../common/typeAssertionFunctions';
import useStations from '../../../datastore/useStations';
import { Staff } from '../../../common/types';

export default function StaffLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const { currentUser } = useAuthStore()
  const { fetchStations } = useStations()

  useEffect(() => {
    fetchStations()
    useStations.subscribe((state) => {
      if (state.stations.length > 0) {
        const staff = currentUser as Staff
        if (staff.stationId && !staff.stationName) {
          const station = state.stations.find((station) => station.id === staff.stationId)
          if (station) {
            staff.stationName = station.name
            useAuthStore.setState({ currentUser: { ...staff } })
          }
        }
      }
    })
  }, [])

  return (
    <Stack flexDirection="row">
      <SideNavbar />
      <Box
        sx={{
          flexGrow: 1,
          borderLeft: `1px solid ${theme.palette.common.black}`,
        }}
      >
        {
          !isStaff(currentUser) ? (
            <Typography>You do not have access to this page</Typography>
          ) : (
            children
          )
        }
      </Box>
    </Stack>
  );
}
