
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { ProductCategoryType } from '../../../common/enums';
import {
  getOrderItemKey,
  groupStaffOrderItems,
} from '../../../common/orderFunctions';
import { StaffOrder, StaffOrderItem, StaffSelectedVariant, WithQty } from '../../../common/types';
import useComponentTypes from '../../../datastore/useComponentTypes';
import useProducts from '../../../datastore/useProducts';
import {
  allColdDrinksFinished,
  allColdDrinksPlaced,
  allColdDrinksStarted,
  allFoodFinished,
  allFoodPending,
  allFoodStarted,
  allHotCoffeeOrTeaFinished,
  allHotCoffeeOrTeaPlaced,
  allHotCoffeeOrTeaStarted,
} from '../functions/stationFunctions';
import { IndicatorBox } from './IndicatorBox';
import PaidStatusIcon from './PaidStatusIcon';
import { TimeIndicator } from './TimeIndicator';

interface DraggableDrinkOrderCardProps {
  order: StaffOrder;
  isAllDrinksStarted: boolean;
  isAllDrinksPlaced: boolean;
  isLoading: boolean;
  isColdDrinks: boolean;
  handleStartOrder: () => Promise<void>;
  handleStopOrder: () => Promise<void>;
  handleFinishOrder: () => Promise<void>;
}

const tableCellStyle = (hasNotes: boolean): SxProps<Theme> => {
  return {
    borderBottom: hasNotes
      ? 0
      : '1px solid rgba(224,224,224,1',
    paddingBottom: hasNotes ? 0 : 1,
    whiteSpace: 'nowrap'
  }
}

export default function DraggableDrinkOrderCard({
  order,
  isAllDrinksStarted,
  isAllDrinksPlaced,
  isLoading,
  isColdDrinks,
  handleStartOrder,
  handleStopOrder,
  handleFinishOrder,
}: DraggableDrinkOrderCardProps) {
  const theme = useTheme();
  const { products } = useProducts();
  const { beansType, milkType, shotsType } = useComponentTypes();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: order.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const categoryTypes = isColdDrinks
    ? [ProductCategoryType.ColdDrinks]
    : [ProductCategoryType.HotCoffee, ProductCategoryType.Tea];
  const groupedItems = groupStaffOrderItems(
    (order as StaffOrder).orderItems,
  ).filter((orderItemWithQty) =>
    categoryTypes.includes(
      orderItemWithQty.productCategoryType as ProductCategoryType,
    ),
  );

  const shotsBeansMilkIds = [beansType?.id, milkType?.id, shotsType?.id].filter(Boolean)

  return (
    <Stack ref={setNodeRef} {...attributes} {...listeners} style={style}>
      <Stack
        sx={{
          backgroundColor: theme.palette.common.grey,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 'auto'
          }}
        >
          <Stack sx={{ padding: 1, alignItems: 'center' }}>
            {isAllDrinksStarted ? <LockIcon /> : <DragHandleIcon />}
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Typography variant="h3" sx={{ marginLeft: theme.spacing(1) }}>
            {order.customerName}
          </Typography>
        </Stack>
        <PaidStatusIcon isPaid={!!order.isPaid} />
        <TimeIndicator time={order.requestedPickupTime} />
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="order table">
          <TableHead>
            <TableRow>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Qty</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Size</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Type</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Milk</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Beans</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Xtra Shots</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>Other</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedItems.map((staffOrderItemWithQty) => {
              const key = getOrderItemKey(staffOrderItemWithQty);
              const product = products.find(
                (f) => f.id === staffOrderItemWithQty.productId,
              );
              const selectedSize = product?.productSizes.find(
                (f) => f.id === staffOrderItemWithQty.productSizeId,
              );
              const milkVariant = staffOrderItemWithQty.selectedVariants.find(
                (f) => f.productVariant.component.componentTypeId === milkType?.id,
              );
              const extraShots = staffOrderItemWithQty.selectedVariants.filter(
                (f) => f.productVariant.component.componentTypeId === shotsType?.id,
              );
              const beansVariant = staffOrderItemWithQty.selectedVariants.find(
                (f) => f.productVariant.component.componentTypeId === beansType?.id,
              );
              const otherVariants = staffOrderItemWithQty.selectedVariants.filter(
                (f) => !shotsBeansMilkIds.includes(f.productVariant.component.componentTypeId)
              )

              const groupedOtherVariants = otherVariants.reduce<{ [key: number]: WithQty<StaffSelectedVariant> }>((acc, variant) => {
                if (!acc[variant.productVariantId]) {
                  acc[variant.productVariantId] = { ...variant, quantity: 0 }
                }
                acc[variant.productVariantId].quantity += 1
                return acc
              }, {})
              const hasNotes = !!staffOrderItemWithQty.notes.length;

              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {staffOrderItemWithQty.quantity}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {selectedSize?.sizeName || '-'}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {staffOrderItemWithQty.productName}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {milkVariant?.productVariant.name || '-'}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {beansVariant?.productVariant.name || '-'}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {extraShots.length || '-'}
                    </TableCell>
                    <TableCell
                      sx={tableCellStyle(hasNotes)}
                    >
                      {otherVariants.length
                        ? Object.values(groupedOtherVariants)
                          .filter(f => !f.productVariant.isCore)
                          .map(variantWithQty => (<Typography fontSize={10} key={variantWithQty.productVariantId}>{variantWithQty.productVariant.name} x{variantWithQty.quantity}</Typography>))
                        : '-'
                      }
                    </TableCell>
                  </TableRow>
                  {!!staffOrderItemWithQty.notes && (
                    <TableRow>
                      <TableCell sx={{ paddingTop: 0 }} colSpan={1}></TableCell>
                      <TableCell
                        sx={{
                          paddingTop: 0,
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                          fontSize: 12
                        }}
                        colSpan={6}
                      >
                        Notes: {staffOrderItemWithQty.notes}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Stack
            sx={{
              justifyContent: 'flex-start',
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {order.orderItems.some(
              (item: StaffOrderItem) =>
                item.productCategoryType === ProductCategoryType.Food,
            ) && (
                <IndicatorBox
                  orderItems={order.orderItems}
                  label="Food"
                  allStartedFn={allFoodStarted}
                  allFinishedFn={allFoodFinished}
                  allPendingFn={allFoodPending}
                />
              )}
            {isColdDrinks &&
              order.orderItems.some(
                (item: StaffOrderItem) =>
                  item.productCategoryType === ProductCategoryType.HotCoffee ||
                  item.productCategoryType === ProductCategoryType.Tea,
              ) && (
                <IndicatorBox
                  orderItems={order.orderItems}
                  label="Hot Coffee"
                  allStartedFn={allHotCoffeeOrTeaStarted}
                  allFinishedFn={allHotCoffeeOrTeaFinished}
                  allPendingFn={allHotCoffeeOrTeaPlaced}
                />
              )}
            {!isColdDrinks &&
              order.orderItems.some(
                (item: StaffOrderItem) =>
                  item.productCategoryType === ProductCategoryType.ColdDrinks,
              ) && (
                <IndicatorBox
                  orderItems={order.orderItems}
                  label="Cold Drinks"
                  allStartedFn={allColdDrinksStarted}
                  allFinishedFn={allColdDrinksFinished}
                  allPendingFn={allColdDrinksPlaced}
                />
              )}
          </Stack>

          <Stack
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flex: 1,
            }}
          >
            {isAllDrinksPlaced && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  margin: 1,
                  width: '10em',
                }}
                disabled={isLoading}
                onClick={handleStartOrder}
              >
                Start Order
              </Button>
            )}
            {isAllDrinksStarted && (
              <Stack sx={{ flexDirection: 'row' }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  sx={{
                    margin: 1,
                    whiteSpace: 'nowrap',
                    width: '10em',
                  }}
                  disabled={isLoading}
                  onClick={handleStopOrder}
                >
                  Reset Order
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    whiteSpace: 'nowrap',
                    margin: 1,
                  }}
                  disabled={isLoading}
                  onClick={handleFinishOrder}
                >
                  Finish Order
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </TableContainer>
    </Stack>
  );
}
