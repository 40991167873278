import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { phoneRules } from '../../../common/formValidationRules';
import LocalStorageSvc, {
  USER_DATA_KEY,
} from '../../../common/localStorageSvc';
import { Customer } from '../../../common/types';
import useAuthStore from '../../../datastore/useAuth';
import useAlert from '../../../hooks/useAlert';

export default function CustomerProfilePage() {
  const theme = useTheme();
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const { updateCustomerProfile: updateProfile, forgotPassword } = useAuthStore();
  const customerProfile = LocalStorageSvc.load<Customer>(USER_DATA_KEY);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: customerProfile
      ? ({
        phoneNumber: customerProfile.phoneNumber,
        name: [customerProfile.firstName, customerProfile.lastName]
          .filter(Boolean)
          .join(' '),
      } as FieldValues)
      : ({
        phoneNumber: '',
        name: '',
      } as FieldValues),
  });

  const sendResetPassword = async () => {
    setLoading(true);
    try {
      await forgotPassword(customerProfile!.email);
      showAlert('Check your email inbox!', 'success');
    } catch (error) {
      console.error(error);
      showAlert('Failed to send password reset email :(', 'error');
    } finally {
      setLoading(false);
    }
  };

  const patchUpdate = async (form: { [key: string]: string }) => {
    setLoading(true);
    try {
      const updated = await updateProfile(form['name'], form['phoneNumber']);
      if (updated) {
        reset({
          phoneNumber: updated.phoneNumber,
          name: [updated.firstName, updated.lastName].filter(Boolean).join(' '),
        });
        showAlert('Profile updated!', 'success');
      }
    } catch {
      showAlert('Update failed :(', 'error');
    } finally {
      setLoading(false);
    }
  };

  const submitForm = () => {
    handleSubmit(patchUpdate)();
  };

  return (
    <Stack spacing={2} sx={{ paddingX: 2, paddingTop: 2 }}>
      <Typography variant="h2">Profile</Typography>
      <Typography variant="body2">{customerProfile?.email}</Typography>
      <Controller
        name="name"
        control={control}
        defaultValue={''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Name'}
            variant="filled"
            InputLabelProps={{
              shrink: true,
              style: {
                color: errors['name']
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              },
            }}
            disabled={loading}
            error={!!errors['name']}
            helperText={errors['name']?.message as string}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        defaultValue={''}
        rules={{ required: false, ...phoneRules() }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Phone'}
            variant="filled"
            InputLabelProps={{
              shrink: true,
              style: {
                color: errors['phoneNumber']
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              },
            }}
            disabled={loading}
            error={!!errors['phoneNumber']}
            helperText={errors['phoneNumber']?.message as string}
          />
        )}
      />

      <Divider sx={{ marginTop: 'auto' }} />
      <Button
        color="primary"
        variant="contained"
        onClick={submitForm}
        sx={{ padding: '1em 0' }}
        disabled={loading}
      >
        Update Profile
      </Button>
      <Button variant="text" onClick={sendResetPassword} disabled={loading}>
        Reset Password
      </Button>
    </Stack>
  );
}
