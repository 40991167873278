import { SurchargeEndpoint } from '../common/endpoints';
import { ServerResponse, Surcharge } from '../common/types';
import { getRequest } from './requests';

export async function get() {
  const result: ServerResponse<Surcharge> = await getRequest(SurchargeEndpoint)
  return result;
}

export default {
  get,
};
