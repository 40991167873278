import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { OrderItemStatus, Stations } from '../../../../common/enums';
import {
  groupStaffOrderItems
} from '../../../../common/orderFunctions';
import { isStaff } from '../../../../common/typeAssertionFunctions';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import useAuthStore from '../../../../datastore/useAuth';
import useOrders from '../../../../datastore/useOrders';
import useStations from '../../../../datastore/useStations';
import useAlert from '../../../../hooks/useAlert';
import PaidStatusIcon from '../../components/PaidStatusIcon';
import StationHeader from '../../components/StationHeader';
import { TimeIndicator } from '../../components/TimeIndicator';
import {
  allHotCoffeeOrTeaStarted
} from '../../functions/stationFunctions';

export default function OpposingStation() {
  const theme = useTheme();
  const { showAlert } = useAlert();
  const { currentUser: user } = useAuthStore();
  const { stations = [] } = useStations();
  const [takeOrderId, setTakeOrderId] = useState<number | null>(null);
  const { fetchOrders, moveOrder, orders, isUpdatingOrder: isLoading } = useOrders()

  if (!user || !isStaff(user)) {
    return null;
  }

  const stationId = user.stationId;
  const stationName = user.stationName;

  const opposingStationName = stationName === Stations.StationOne ? Stations.StationTwo : Stations.StationOne
  const opposingStationId = stations?.find((station) => station.name === opposingStationName)?.id

  async function handleTakeOrder() {
    if (!takeOrderId || !stationId) {
      console.error('Failed to take order due to invalid order or station id');
      showAlert('Failed to take order', 'error');
      return;
    }
    try {
      await moveOrder(takeOrderId, stationId)
      await fetchOrders()
      setTakeOrderId(null);
      showAlert('Order moved', 'success');
    } catch (err) {
      showAlert('Failed to take order, please refresh and try again.', 'error');
    }
  }

  const sortedFilteredOrders = orders.filter(order => {
    const opposingItems = order.orderItems.filter(orderItem => orderItem.stationId === opposingStationId && orderItem.status !== OrderItemStatus.Finished)
    return opposingItems.length > 0
  }).map((_order) => {
    const order = {
      ..._order,
      requestedPickupTime: _order.requestedPickupTime
        ? new Date(_order.requestedPickupTime)
        : null,
      startTime: _order.startTime ? new Date(_order.startTime) : null,
      finishTime: _order.finishTime ? new Date(_order.finishTime) : null,
    }

    const isAllHotCoffeeStarted = allHotCoffeeOrTeaStarted(order.orderItems);
    const filteredOrderItems = order.orderItems.filter(
      (orderItem) => orderItem.stationId === opposingStationId,
    );
    const groupedItems = groupStaffOrderItems(filteredOrderItems, true);
    return { id: order.id, order, isAllHotCoffeeStarted, groupedItems };
  });

  return (
    <Grid
      size={{
        xs: 2,
        md: 4,
      }}
      sx={{
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <StationHeader isOpposingStation />
      <Divider />
      <Box
        sx={{
          overflowY: 'auto',
          height: 'calc(100vh - 64px)',
        }}
      >
        <Grid container spacing={1} sx={{ padding: 3 }}>
          {sortedFilteredOrders.map(
            ({ order, isAllHotCoffeeStarted, groupedItems }) => {
              return (
                <Grid size={12} key={order.id}>
                  <Paper
                    elevation={8}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      padding: 0,
                      margin: 0,
                      height: '100%',
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.common.deepblue,
                    }}
                  >
                    <Stack
                      sx={{
                        backgroundColor: theme.palette.common.grey,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: 0,
                      }}
                    >
                      <Stack
                        sx={{ flexDirection: 'row', alignItems: 'center', marginRight: 'auto' }}
                      >
                        {isAllHotCoffeeStarted && (
                          <>
                            <Stack sx={{ padding: 1, alignItems: 'center' }}>
                              <LockIcon />
                            </Stack>
                            <Divider orientation="vertical" flexItem />
                          </>
                        )}
                        <Typography
                          variant="h3"
                          sx={{ marginLeft: theme.spacing(1) }}
                        >
                          {order.customerName}
                        </Typography>
                      </Stack>
                      <PaidStatusIcon isPaid={!!order.isPaid} />
                      <TimeIndicator time={order.requestedPickupTime} />
                    </Stack>
                    <TableContainer component={Paper}>
                      <Table aria-label="order table" size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Qty</TableCell>
                            <TableCell>Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupedItems.map((orderItemWithQty, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  {orderItemWithQty.quantity}
                                </TableCell>
                                <TableCell>
                                  {orderItemWithQty.productName}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <Stack
                        sx={{
                          alignItems: 'center',
                        }}
                      >
                        {!isAllHotCoffeeStarted && (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              margin: 1,
                              width: '20em',
                            }}
                            onClick={() => {
                              setTakeOrderId(order.id ?? '');
                            }}
                            disabled={isLoading}
                          >
                            Take Order
                          </Button>
                        )}
                      </Stack>
                    </TableContainer>
                  </Paper>
                </Grid>
              );
            },
          )}
        </Grid>
      </Box>
      {takeOrderId && (
        <ConfirmModal
          onClose={() => {
            setTakeOrderId(null);
          }}
          title="Take Order"
          content="Are you sure you want to take this order?"
          onConfirm={handleTakeOrder}
        />
      )}
    </Grid>
  );
}
