/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { OrderItemStatus, ProductCategoryType } from '../../../common/enums';
import { isItemType } from '../../../common/orderFunctions';
import InfoCard from '../../../components/card/InfoCard';
import useOrders from '../../../datastore/useOrders';
import { allCategoryItemsAreStatus, sortedUnfinishedOrdersForStation } from '../functions/stationFunctions';
import DraggableOrderCard from './DraggableOrderCard';

interface OrderQueueProps {
    header?: () => JSX.Element
    categories: ProductCategoryType[]
    isWalkup?: boolean
    stationId?: number
}

const OrderQueue = ({ categories, isWalkup = false, stationId = 0, header : Header, children }: PropsWithChildren<OrderQueueProps>) => {
    const theme = useTheme()
    const { orders, updateOrderPositions, loading } = useOrders()

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    })
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    })
    const keyboardSensor = useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    })
    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        keyboardSensor,
    );

    const mappedOrders = orders.map(order => ({
        ...order,
        queueOrder: order.orderItems.find(item => isItemType(item, categories))?.queueOrder,
        requestedPickupTime: order.requestedPickupTime
            ? new Date(order.requestedPickupTime)
            : null,
        startTime: order.startTime ? new Date(order.startTime) : null,
        finishTime: order.finishTime ? new Date(order.finishTime) : null,
    }))

    const filteredAndSortedOrders = sortedUnfinishedOrdersForStation(mappedOrders, stationId, categories)

    const isHotDrinks = categories.includes(ProductCategoryType.HotCoffee)

    const handleDragEnd = async (event: any) => {
        const { active, over } = event;

        if (!over || active.id === over.id) return;

        const handleIt = async () => {
            const oldIndex = filteredAndSortedOrders.findIndex(order => order.id === active.id);
            const newIndex = filteredAndSortedOrders.findIndex(order => order.id === over.id);

            const reorderedOrders = arrayMove(filteredAndSortedOrders, oldIndex, newIndex);

            const orderDtos = reorderedOrders.map((order, index) => ({
                id: order.id,
                orderItems: order.orderItems.map((item) => ({
                    id: item.id,
                    queueOrder: isItemType(item, categories) ? index + 1 : item.queueOrder,
                })),
            }));

            try {
                await updateOrderPositions({ orderDtos, productCategories: categories });
            } catch (error) {
                console.error('Failed to update order positions');
            }
        }
        if (loading) {
            setTimeout(async () => await handleIt(), 250)
        } else {
            await handleIt()
        }
    };

    return (
        <Grid
            size={{ xs: isWalkup ? 6 : isHotDrinks ? 10 : 12, md: isWalkup ? 6 : isHotDrinks ? 8 : 12 }}
            sx={{
                padding: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {Header ? Header() : null}
            <Divider />
            {filteredAndSortedOrders.length === 0 ? (
                <InfoCard
                    Icon={NotificationsIcon}
                    title="No Results Found"
                    subtitle="Try refreshing your page or check back later."
                />
            ) : (
                <Box
                    sx={{
                        overflowY: 'auto',
                        height: 'calc(100vh - 64px)',
                    }}
                >
                    <Grid container spacing={1} sx={{ padding: 3 }}>
                        {children}
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            modifiers={[restrictToVerticalAxis]}
                        >
                            <SortableContext
                                items={filteredAndSortedOrders.map(order => order.id)}
                                strategy={verticalListSortingStrategy}
                            >
                                {filteredAndSortedOrders.map((order) => {
                                    const allPlaced = allCategoryItemsAreStatus(order.orderItems, categories, OrderItemStatus.Placed)
                                    const allStarted = allCategoryItemsAreStatus(order.orderItems, categories, OrderItemStatus.Started)
                                    return (
                                        <Grid size={12} key={order.id}>
                                            <Paper
                                                elevation={8}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    padding: 0,
                                                    margin: 0,
                                                    height: '100%',
                                                    backgroundColor: theme.palette.common.white,
                                                    color: theme.palette.common.deepblue,
                                                }}
                                            >
                                                <DraggableOrderCard
                                                    order={order}
                                                    categories={categories}
                                                    allPlaced={allPlaced}
                                                    allStarted={allStarted}
                                                    isWalkup={isWalkup}
                                                    stationId={stationId}
                                                />
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                            </SortableContext>
                        </DndContext>
                    </Grid>
                </Box>
            )}
        </Grid>
    );
};

export default OrderQueue;