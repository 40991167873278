import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { ProductCategoryType, Stations } from '../../../common/enums';
import { isStaff } from '../../../common/typeAssertionFunctions';
import useAuthStore from '../../../datastore/useAuth';
import useOrders from '../../../datastore/useOrders';
import useStations from '../../../datastore/useStations';
import OrderQueue from '../components/OrderQueue';
import WalkStationHeader from '../components/WalkStationHeader';


const categories = [ProductCategoryType.HotCoffee, ProductCategoryType.Tea];

export default function WalkUpPage() {
  const { currentUser } = useAuthStore();
  const { startPolling, stopPolling } = useOrders();
  const { stations = [] } = useStations();
  const theme = useTheme();

  useEffect(() => {
    startPolling({ initialInterval: 5000, maxInterval: 15000 });
    return () => {
      stopPolling();
    };
  }, []);

  const stationOne = stations?.find(
    (station) => station.name === Stations.StationOne,
  );
  const stationTwo = stations?.find(
    (station) => station.name === Stations.StationTwo,
  );

  if (!stationOne || !stationTwo || !isStaff(currentUser)) return;

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.common.white,
        height: '100vh',
      }}
    >
      <OrderQueue
        categories={categories}
        isWalkup={true}
        header={() => <WalkStationHeader stationName={Stations.StationOne} />}
        stationId={stationOne.id}
      />
      <OrderQueue
        categories={categories}
        isWalkup={true}
        header={() => <WalkStationHeader stationName={Stations.StationTwo} />}
        stationId={stationTwo.id}
      />
    </Grid>
  );
}
