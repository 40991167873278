import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { add } from 'date-fns/add';
import { isAfter } from 'date-fns/isAfter';
import { startOfMinute } from 'date-fns/startOfMinute';
import { useState } from 'react';
import { OrderingAvailabilityStatus } from '../../../common/enums';
import { earliestPickupTime, formatTime, latestPickupTime } from '../../../common/timeFunctions';
import { Order } from '../../../common/types';
import useSites from '../../../datastore/useSites';
import useSelectedSite from '../../../hooks/useSelectedSite';
import StyledTimeButton from './StyledButtton';

type Props = {
    order: Partial<Order>
    setOrder: (value: Partial<Order>) => void
}

export default function PickupTime({ order, setOrder }: Props) {
    const { selectedSiteId } = useSelectedSite();
    const { getSiteOrderingAvailabilityStatus } = useSites();
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [isPickupAsap, setIsPickupAsap] = useState<boolean>(
        () => (order?.requestedPickupTime || new Date()) <= new Date(),
    );

    const onlineOrderingStatus = getSiteOrderingAvailabilityStatus(selectedSiteId)

    const onSelectPickupASAP = (isASAP: boolean) => {
        setIsPickupAsap(isASAP);
        if (isASAP) {
            setOrder({ requestedPickupTime: null });
        } else {
            setOrder({ requestedPickupTime: minOrderTime });
        }
        setShowPicker(false);
    }

    const onSelectTimePressed = () => {
        onSelectPickupASAP(false);
        setShowPicker(true);
    }

    const today = new Date()
    let now = new Date(today);

    if (now > latestPickupTime(now)) {
        now = add(now, { days: 1 });
        now = earliestPickupTime(now);
    }

    const earliestOrderAt = earliestPickupTime(now);
    const latestOrderAt = latestPickupTime(now);

    const nowMinute = startOfMinute(now);
    const minOrderTime = isAfter(nowMinute, earliestOrderAt)
        ? nowMinute
        : earliestOrderAt;

    const selectedTimeLabel = !isPickupAsap && order.requestedPickupTime ? `${onlineOrderingStatus}, ${formatTime(order.requestedPickupTime)}` : 'Select time'

    if (![OrderingAvailabilityStatus.Today, OrderingAvailabilityStatus.Tomorrow].includes(onlineOrderingStatus)) {
        return null
    }

    return (
        <>
            <Typography fontWeight="bold">Pickup Time</Typography>
            <ButtonGroup
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 2,
                }}
            >
                <StyledTimeButton
                    onClick={() => onSelectPickupASAP(true)}
                    sx={{ flex: 1 }}
                    disabled={isPickupAsap}
                >
                    ASAP
                </StyledTimeButton>
                <StyledTimeButton
                    onClick={onSelectTimePressed}
                    sx={{ flex: 1 }}
                    isTimeSelect={!isPickupAsap}
                >
                    {selectedTimeLabel}
                </StyledTimeButton>
            </ButtonGroup>
            <MobileTimePicker
                open={showPicker}
                label="Select Time"
                views={['hours', 'minutes']}
                ampm={false}
                value={order.requestedPickupTime || new Date()}
                slots={{
                    textField: () => null
                }}
                minTime={minOrderTime}
                maxTime={latestOrderAt}
                onChange={(newValue) =>
                    setOrder({
                        requestedPickupTime: newValue,
                    })
                }
                onAccept={() => setShowPicker(false)}
                onClose={() => setShowPicker(false)}
            />
        </>
    )
}