import { ProductCategoryType } from '../../../common/enums'
import { StaffOrder } from '../../../common/types'
import useOrders from '../../../datastore/useOrders'
import useAlert from '../../../hooks/useAlert'
import DraggableDrinkOrderCard from './DraggableDrinkOrderCard'
import DraggableFoodOrderCard from './DraggableFoodOrderCard'
import DraggableStationCard from './DraggableWalkupCard'

interface DraggableOrderCard {
    order: StaffOrder
    categories: ProductCategoryType[]
    isWalkup?: boolean
    allPlaced: boolean
    allStarted: boolean
    stationId: number
}
export default function DraggableOrderCard({ order, categories, isWalkup, allPlaced, allStarted, stationId }: DraggableOrderCard) {
    const { showAlert } = useAlert()
    const { isUpdatingOrder, startOrder, stopOrder, finishOrder, fetchOrders } = useOrders()
    const isColdDrinks = categories.includes(ProductCategoryType.ColdDrinks);
    const isFoods = categories.includes(ProductCategoryType.Food);

    const handleStartOrder = async () => {
        try {
            await startOrder(order, categories)
            showAlert('Order started', 'success')
            fetchOrders()
        } catch (err) {
            showAlert('Failed to start order', 'error')
        }
    }

    const handleStopOrder = async () => {
        try {
            await stopOrder(order, categories)
            showAlert('Order stopped', 'success')
            fetchOrders()
        } catch (err) {
            showAlert('Failed to stop order', 'error')
        }
    }

    const handleFinishOrder = async () => {
        try {
            await finishOrder(order, categories)
            showAlert('Order updated', 'success')
            fetchOrders()
        } catch (err) {
            showAlert('Failed to update order', 'error')
        }
    }

    if (isWalkup) {
        return (
            <DraggableStationCard
                order={order}
                isAllStarted={allStarted}
                stationId={stationId}
            />
        )
    }

    if (isFoods) {
        return (
            <DraggableFoodOrderCard
                order={order}
                isAllFoodPlaced={allPlaced}
                isAllFoodStarted={allStarted}
                isLoading={isUpdatingOrder}
                handleStartOrder={handleStartOrder}
                handleStopOrder={handleStopOrder}
                handleFinishOrder={handleFinishOrder}
            />
        )
    }

    return (
        <DraggableDrinkOrderCard
            order={order}
            isAllDrinksPlaced={allPlaced}
            isAllDrinksStarted={allStarted}
            isColdDrinks={isColdDrinks}
            isLoading={isUpdatingOrder}
            handleStartOrder={handleStartOrder}
            handleStopOrder={handleStopOrder}
            handleFinishOrder={handleFinishOrder}
        />
    )

}