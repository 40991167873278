import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { isAdmin, isStaff } from './common/typeAssertionFunctions.tsx';
import useAuthStore from './datastore/useAuth.tsx';
import AccountsPage from './pages/Admin/accounts/AccountsPage.tsx';
import ComponentArchive from './pages/Admin/componentManagement/archive/ComponentArchive.tsx';
import ComponentsPage from './pages/Admin/componentManagement/ComponentsPage.tsx';
import ComponentTypeArchive from './pages/Admin/componentTypes/archive/ComponentTypeArchive.tsx';
import ComponentTypesPage from './pages/Admin/componentTypes/ComponentTypesPage.tsx';
import CustomerManagementPage from './pages/Admin/customers/CustomerManagementPage.tsx';
import PopularProductsPage from './pages/Admin/popular/PopularProductsPage.tsx';
import ProductsPage from './pages/Admin/products/ProductPage.tsx';
import VariationPage from './pages/Admin/products/variations/VariationPage.tsx';
import ReportsPage from './pages/Admin/reports/ReportsPage.tsx';
import CustomerCheckoutPage from './pages/customer/checkout/CustomerCheckoutPage';
import CustomerHomePage from './pages/customer/home/CustomerHomePage';
import CustomerLayout from './pages/customer/layout/CustomerLayout';
import OrderConfirmed from './pages/customer/orderConfirmed/OrderConfirmed.tsx';
import CustomerProfilePage from './pages/customer/profile/CutomerProfilePage';
import PasswordResetPage from './pages/passwordReset/PasswordResetPage.tsx';
import StaffHomePage from './pages/staff/home/StaffHomePage';
import StaffLayout from './pages/staff/layout/StaffLayout';
import OrderPage from './pages/staff/order/OrderPage';
import OrderDetailsPage from './pages/staff/pos/OrderDetailsPage.tsx';
import PosPage from './pages/staff/pos/PosPage';
import StaffSettings from './pages/staff/settings/StaffSettings';
import WalkUpPage from './pages/staff/walkup/WalkUpPage';

const CustomerRoute = () => {
  const currentUser = useAuthStore(state => state.currentUser)
  if (!currentUser || isStaff(currentUser)) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />
};

const StaffRoute = () => {
  const currentUser = useAuthStore(state => state.currentUser)
  if (!currentUser || !isStaff(currentUser)) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />
};

const AdminRoute = () => {
  const currentUser = useAuthStore(state => state.currentUser)
  if (!currentUser || (isStaff(currentUser) && !isAdmin(currentUser))) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />
};


const publicRoutes = [
  {
    path: '',
    element: (
      <CustomerLayout>
        <CustomerHomePage />
      </CustomerLayout>
    ),
  },
  {
    path: 'checkout',
    element: (
      <CustomerLayout>
        <CustomerCheckoutPage />
      </CustomerLayout>
    ),
  },
  {
    path: 'forgot_password',
    element: (
      <CustomerLayout>
        <PasswordResetPage mode="forgot" />
      </CustomerLayout>
    ),
  },
  {
    path: 'reset_password',
    element: (
      <CustomerLayout>
        <PasswordResetPage mode="reset" />
      </CustomerLayout>
    ),
  },
]

const customerRoutes = [
  {
    path: 'profile',
    element: (
      <CustomerLayout>
        <CustomerProfilePage />
      </CustomerLayout>
    ),
  },
  {
    path: 'orderconfirmed/:orderId',
    element: (
      <CustomerLayout>
        <OrderConfirmed />
      </CustomerLayout>
    ),
  },
]

const staffRoutes = [
  {
    path: 'home',
    element: (
      <StaffLayout>
        <StaffHomePage />
      </StaffLayout>
    ),
  },
  {
    path: 'order',
    element: (
      <StaffLayout>
        <OrderPage />
      </StaffLayout>
    ),
  },
  {
    path: 'pos',
    element: (
      <StaffLayout>
        <PosPage />
      </StaffLayout>
    ),
  },
  {
    path: 'pos/:orderId',
    element: (
      <StaffLayout>
        <OrderDetailsPage />
      </StaffLayout>
    ),
  },
  {
    path: 'settings',
    element: (
      <StaffLayout>
        <StaffSettings />
      </StaffLayout>
    ),
  },
  {
    path: 'walkup',
    element: (
      <StaffLayout>
        <WalkUpPage />
      </StaffLayout>
    ),
  },
]
const adminRoutes = [
  {
    path: 'reports',
    element: (
      <StaffLayout>
        <ReportsPage />
      </StaffLayout>
    ),
  },
  {
    path: 'products',
    element: (
      <StaffLayout>
        <ProductsPage />
      </StaffLayout>
    ),
  },
  {
    path: 'products/:productId',
    element: (
      <StaffLayout>
        <VariationPage />
      </StaffLayout>
    ),
  },
  {
    path: 'components',
    element: (
      <StaffLayout>
        <ComponentsPage />
      </StaffLayout>
    ),
  },
  {
    path: 'components/:componentId/archive',
    element: (
      <StaffLayout>
        <ComponentArchive />
      </StaffLayout>
    ),
  },
  {
    path: 'componenttypes',
    element: (
      <StaffLayout>
        <ComponentTypesPage />
      </StaffLayout>
    ),
  },
  {
    path: 'componenttypes/:componentTypeId/archive',
    element: (
      <StaffLayout>
        <ComponentTypeArchive />
      </StaffLayout>
    ),
  },
  {
    path: 'popular',
    element: (
      <StaffLayout>
        <PopularProductsPage />
      </StaffLayout>
    ),
  },
  {
    path: 'customers',
    element: (
      <StaffLayout>
        <CustomerManagementPage />
      </StaffLayout>
    ),
  },
  {
    path: 'accounts',
    element: (
      <StaffLayout>
        <AccountsPage />
      </StaffLayout>
    ),
  },
]

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {
          publicRoutes.map((route) => {
            return <Route key={route.path} path={route.path} element={route.element} />
          })
        }
        <Route element={<CustomerRoute />}>
          {
            customerRoutes.map(route => <Route key={route.path} path={route.path} element={route.element} />)
          }
        </Route>

        <Route path="/staff" element={<StaffRoute />}>
          {
            staffRoutes.map(route => <Route key={route.path} path={route.path} element={route.element} />)
          }
        </Route>

        <Route path="/admin" element={<AdminRoute />}>
          {
            adminRoutes.map(route => <Route key={route.path} path={route.path} element={route.element} />)
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AllRoutes