import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Controller, FieldErrors, FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  confirmPasswordRules,
  emailRules,
  passwordRules,
} from '../../common/formValidationRules';
import StyledButton from '../../components/button/StyledButton';
import useAuthStore from '../../datastore/useAuth';
import useAlert from '../../hooks/useAlert';
import usePasswordVisibility from '../../hooks/usePasswordVisibility';

type PasswordResetMode = 'forgot' | 'reset';

type PasswordResetPageProps = {
  mode: PasswordResetMode;
};

type ModeTexts = {
  title: string;
  subtitle: string;
  submitButton: string;
};

interface PasswordForm extends FieldValues {
  email: string;
  password: string;
  confirm_password: string;
}

function textForMode(mode: PasswordResetMode): ModeTexts {
  switch (mode) {
    case 'reset':
      return {
        title: 'Set New Password',
        subtitle: 'Set new Password',
        submitButton: 'Set new Password',
      };
    default:
      return {
        title: 'Forgot Password',
        subtitle: 'We will email a link to securely reset your password',
        submitButton: 'Send Email',
      };
  }
}

function hasErrorsForMode(
  mode: PasswordResetMode,
  errors: FieldErrors<FieldValues>,
): boolean {
  if (mode === 'forgot') {
    return !!errors['email'];
  }
  return Object.keys(errors).length > 0;
}

const PASSWORD_ALERT_DURATION = 10000;

export default function PasswordResetPage({ mode }: PasswordResetPageProps) {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { title, subtitle, submitButton } = textForMode(mode);

  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const { resetPassword, forgotPassword } = useAuthStore();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { visible: showPassword, toggle: togglePassword } =
    usePasswordVisibility();
  const { visible: showConfirmPassword, toggle: toggleConfirmPassword } =
    usePasswordVisibility();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<PasswordForm>({
    defaultValues: { email: '', password: '', confirm_password: '' },
  });

  const password = watch('password', '');

  const submitDisabled = loading || hasErrorsForMode(mode, errors);

  const submitNewPassword = async (form: { [key: string]: string }) => {
    setLoading(true);
    try {
      await resetPassword(
        form['email'] ?? '',
        decodeURIComponent(token ?? ''),
        form['password'],
      );
      showAlert('Password reset!', 'success', PASSWORD_ALERT_DURATION);
      reset();
      navigate('/');
    } catch(err) {
      console.error(err)
      showAlert('Password reset failed :(', 'error', PASSWORD_ALERT_DURATION);
    } finally {
      setLoading(false);
    }
  };

  const submitForgotPassword = async (form: { [key: string]: string }) => {
    setLoading(true);
    try {
      await forgotPassword(form['email']);
      showAlert(
        'Check email inbox for password reset link!',
        'success',
        PASSWORD_ALERT_DURATION,
      );
      reset();
      navigate('/');
    } catch {
      showAlert(
        'Password reset request failed :(',
        'error',
        PASSWORD_ALERT_DURATION,
      );
    } finally {
      setLoading(false);
    }
  };

  const submitForm = () => {
    if (mode === 'forgot') {
      handleSubmit(submitForgotPassword)();
    } else {
      handleSubmit(submitNewPassword)();
    }
  };

  return (
    <Stack spacing={2} sx={{ paddingX: 2, paddingTop: 2 }}>
      <Typography variant="h2">{title}</Typography>
      <Typography>{subtitle}</Typography>
      <Controller
        name={'email'}
        control={control}
        defaultValue={''}
        rules={{ required: true, ...emailRules() }}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            label={'Email'}
            variant="filled"
            InputLabelProps={{
              shrink: true,
              style: {
                color: errors['email']
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              },
            }}
            disabled={loading}
            error={!!errors['email']}
            helperText={errors['email']?.message as string}
          />
        )}
      />

      {mode === 'reset' && (
        <>
          <Controller
            name={'password'}
            control={control}
            defaultValue={''}
            rules={passwordRules()}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label={'New Password'}
                variant="filled"
                type={showPassword ? 'text' : 'password'}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    style: {
                      color: errors['password']
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    },
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                disabled={loading}
                error={!!errors['password']}
                helperText={errors['password']?.message as string}
              />
            )}
          />
          <Controller
            name={'confirm_password'}
            control={control}
            defaultValue={''}
            rules={confirmPasswordRules(password)}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label={'Confirm New Password'}
                variant="filled"
                type={showConfirmPassword ? 'text' : 'password'}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                    style: {
                      color: errors['confirm_password']
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    },
                  },
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={toggleConfirmPassword}
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                }}
                disabled={loading}
                error={!!errors['confirm_password']}
                helperText={errors['confirm_password']?.message as string}
              />
            )}
          />
        </>
      )}
      <StyledButton
        variant="contained"
        disabled={submitDisabled}
        onClick={submitForm}
      >
        {submitButton}
      </StyledButton>
    </Stack>
  );
}
