import HomeIcon from '@mui/icons-material/Home';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Stations } from '../../../common/enums';
import { isStaff } from '../../../common/typeAssertionFunctions';
import useAuthStore from '../../../datastore/useAuth';
import CurrentTime from './CurrentTime';

interface StationHeaderProps {
  isOpposingStation?: boolean;
}

export default function StationHeader({
  isOpposingStation,
}: StationHeaderProps) {
  const theme = useTheme();
  const { currentUser } = useAuthStore();

  if (!isStaff(currentUser)) return;

  const stationName = isOpposingStation
    ? currentUser.stationName === Stations.StationOne
      ? Stations.StationTwo
      : Stations.StationOne
    : currentUser.stationName;

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 1,
      }}
    >
      {isOpposingStation ? (
        <Stack sx={{ flexDirection: 'row' }}>
          <Typography
            variant="h2"
            fontWeight="600"
            sx={{ marginRight: theme.spacing(2), paddingLeft: 1 }}
          >
            {stationName}
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{ flexDirection: 'row' }}>
          <HomeIcon fontSize="large" />
          <Typography
            variant="h2"
            fontWeight="600"
            sx={{ marginRight: theme.spacing(2), paddingLeft: 1 }}
          >
            {stationName}
          </Typography>
        </Stack>
      )}

      {!isOpposingStation && <CurrentTime />}
    </Stack>
  );
}
