export const enum ReportTypes {
  TotalSales = 'totalSales',
  ComponentConsumption = 'componentConsumption',
  RevenueBreakdown = 'revenueBreakdown',
  ProductsSold = 'productsSold',
  AverageSpend = 'averageSpend'
}

export enum Role {
  Admin = 'AdminStaff',
  General = 'GeneralStaff',
  Customer = 'Customer',
}

export enum ProductCategoryType {
  HotCoffee = 'Hot Coffee',
  ColdDrinks = 'Cold Drinks',
  Tea = 'Tea',
  Food = 'Food',
}

export enum ReportTimePeriods {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Yearly = 3
}

export const enum Stations {
  StationOne = 'Station 1',
  StationTwo = 'Station 2',
  Food = 'Food',
  ColdDrinks = 'Cold Drinks',
  AutoAssign = 'Auto', //not explicitly a station but used to 'auto-assign' a station.
}

export const enum PaymentType {
  Cash = 'Cash',
  Card = 'Card',
  Stripe = 'Stripe',
  Defer = 'Defer'
}

export const enum Environment {
  Production = 'prod',
  Test = 'test',
  NonProd = 'np',
  Development = 'dev',
}

export enum ProductSize {
  Small = 'Small',
  Regular = 'Regular',
  Large = 'Large',
}


export const enum GST {
  TenPercent = 0.1,
}

export const enum OrderItemStatus {
  Placed = 'Placed', //order item has been placed by staff or customer
  Started = 'Started', //order item has been started by the staff
  Finished = 'Finished', //order item has be finished by the staff
}

export const enum TimerWarningColors {
  Red = 'red',
  Orange = 'orange',
  Green = 'green',
}


export const enum MilkVariation {
  FullCream = 'Full Cream Milk',
  Skim = 'Skim Milk',
  Almond = 'Almond Milk',
  Soy = 'Soy Milk',
  Oat = 'Oat Milk',
}

export const enum TabletSize {
  Small = '(max-width: 1024px)',
  Medium = '(min-width: 1025px) and (max-width: 1279px)',
  Large = '(min-width: 1280px) and (max-width: 1535px)',
}

export const enum Measurement {
  Grams = 'grams',
  Milliliters = 'ml',
}

export const enum TpgeComponentTypeEnum {
  Beans = 'Beans',
  Milk = 'Milk',
  Other = 'Other',
}

export const enum ReportView {
  Table = 'Table',
  Chart = 'Chart',
}

export const enum OpeningHours {
  Morning = 4.5,
  Evening = 18,
}

export enum OrderAdapterType {
  Regular = 'Regular',
  Staff = 'Staff',
}

export enum OrderingAvailabilityStatus {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Unavailable = 'Unavailable',
}