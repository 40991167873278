import { create } from 'zustand';
import ordersApi, { ChangeQueueOrders, GetOrdersParams } from '../api/order';
import { StaffOrder } from '../common/types';
import { OrderItemStatus, ProductCategoryType } from '../common/enums';

interface PollingOptions {
  initialInterval: number;
  maxInterval: number;
}

type OrdersStore = {
  hasLoaded: boolean;
  orders: StaffOrder[];
  loading: boolean;
  error: unknown | null;
  params?: GetOrdersParams;
  isUpdatingOrder: boolean;
  updateOrderPositions: (params: ChangeQueueOrders) => Promise<void>;
  startOrder: (order: StaffOrder, categories: ProductCategoryType[]) => Promise<void>;
  stopOrder: (order: StaffOrder, categories: ProductCategoryType[]) => Promise<void>;
  finishOrder: (order: StaffOrder, categories: ProductCategoryType[]) => Promise<void>;
  moveOrder: (orderId: number, newStationId: number) => Promise<void>;
  resetFilters: () => void;
  fetchOrders: (params?: GetOrdersParams) => Promise<void>;
  startPolling: (options?: PollingOptions) => void;
  stopPolling: () => void;
};

const defaultPollingOptions: PollingOptions = {
  initialInterval: 2000,
  maxInterval: 15000,
};

let _ordersInterval: NodeJS.Timeout | null = null;

const getOrderItemStatusDtos = (order: StaffOrder, categories: ProductCategoryType[], status: OrderItemStatus) => {
  const updatedOrderItems = order.orderItems.map(orderItem => ({
    id: orderItem.id,
    status: categories.includes(orderItem.productCategoryType as ProductCategoryType)
      ? status
      : orderItem.status
  }));
  return updatedOrderItems
};

const useOrders = create<OrdersStore>((set, get) => ({
  hasLoaded: false,
  orders: [],
  loading: false,
  error: null,
  isUpdatingOrder: false,

  resetFilters: () => {
    set({ params: undefined });
  },

  fetchOrders: async (params?: GetOrdersParams) => {
    if (get().loading) {
      return;
    }
    try {
      set({ loading: true, hasLoaded: true, params: params ?? undefined });
      const result = await ordersApi.getOrders(get().params);
      set({ orders: result.data ?? [], loading: false, error: null });
    } catch (err) {
      console.error(err);
      set({ loading: false, error: err });
    }
  },

  updateOrderPositions: async ({ productCategories, orderDtos }: ChangeQueueOrders) => {
    set({ loading: true });

    try {
      const response = await ordersApi.changeQueueOrders({ productCategories, orderDtos });
      if (response.status !== 200) {
        throw new Error('Failed to update order positions');
      }

      // Update local state with new queue orders
      const updatedOrders = get().orders.map(order => {
        const update = orderDtos.find(u => u.id === order.id);
        if (!update || !update?.orderItems?.length) return order;

        return {
          ...order,
          orderItems: order.orderItems.map(item => {
            const updatedItem = update.orderItems!.find(ui => ui.id === item.id);
            return updatedItem ? { ...item, queueOrder: updatedItem.queueOrder } : item;
          })
        };
      });

      set({ orders: updatedOrders, loading: false, error: null });
    } catch (error) {
      set({ loading: false, error });
      throw error
    }
  },

  startOrder: async (order: StaffOrder, categories: ProductCategoryType[]) => {
    set({ isUpdatingOrder: true })
    try {
      const updatedOrderItems = getOrderItemStatusDtos(order, categories, OrderItemStatus.Started);
      const result = await ordersApi.startStopFinishOrder({
        id: order.id,
        finishTime: null,
        startTime: new Date(),
        paymentType: null,
        softDelete: false,
        orderItems: updatedOrderItems,
      })
      if (result.status !== 200) {
        throw new Error('Failed to stop Order')
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      set({ isUpdatingOrder: false })
    }
  },

  stopOrder: async (order: StaffOrder, categories: ProductCategoryType[]) => {
    set({ isUpdatingOrder: true })
    try {
      const updatedOrderItems = getOrderItemStatusDtos(order, categories, OrderItemStatus.Placed);
      const result = await ordersApi.startStopFinishOrder({
        id: order.id,
        finishTime: null,
        startTime: null,
        paymentType: null,
        softDelete: false,
        orderItems: updatedOrderItems,
      })
      if (result.status !== 200) {
        throw new Error('Failed to stop Order')
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      set({ isUpdatingOrder: false })
    }
  },

  finishOrder: async (order: StaffOrder, categories: ProductCategoryType[]) => {
    set({ isUpdatingOrder: true })
    try {
      const updatedOrderItems = getOrderItemStatusDtos(order, categories, OrderItemStatus.Finished);
      const result = await ordersApi.startStopFinishOrder({
        id: order.id,
        startTime: order.startTime,
        finishTime: new Date(),
        paymentType: null,
        softDelete: false,
        orderItems: updatedOrderItems,
      })
      if (result.status !== 200) {
        throw new Error('Failed to mark as Finished')
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      set({ isUpdatingOrder: false })
    }
  },

  moveOrder: async (orderId: number, newStationId: number) => {
    set({ isUpdatingOrder: true })
    try {
      const result = await ordersApi.updateStation({
        orderId,
        newStationId,
        productCategories: [
          ProductCategoryType.HotCoffee,
          ProductCategoryType.Tea,
        ],
      });

      if (result.status !== 200) {
        console.error('Failed to move order, server error');
        throw new Error('Failed to move order, please refresh and try again.');
      }
    } catch (err) {
      console.error(err)
      throw err
    } finally {
      set({ isUpdatingOrder: false })
    }
  },

  startPolling: (options = defaultPollingOptions) => {
    const { initialInterval, maxInterval } = options;
    const pollingInterval =
      initialInterval > maxInterval ? maxInterval : initialInterval;
    get().fetchOrders(get().params);
    if (_ordersInterval) {
      clearInterval(_ordersInterval);
    }
    _ordersInterval = setInterval(() => {
      get().fetchOrders(get().params);
    }, pollingInterval);
  },
  stopPolling: () => {
    if (_ordersInterval) {
      clearInterval(_ordersInterval);
    }
  },
}));

export default useOrders;

if (!useOrders.getState().hasLoaded) {
  useOrders.getState().fetchOrders();
}
