import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrderHistory } from '../../../api/customerOrders';
import { ProductCategoryType } from '../../../common/enums';
import LocalStorageSvc, {
  SELECTED_SITE_KEY,
} from '../../../common/localStorageSvc';
import {
  formatCurrency,
  productBasePrice,
} from '../../../common/moneyFunctions';
import {
  mapOrderSummaryToOrder,
  mapProductToOrderItem,
} from '../../../common/orderFunctions';
import {
  Order,
  OrderItem,
  OrderSummary,
  PopularProduct,
  Product,
  Site,
} from '../../../common/types';
import StyledButton from '../../../components/button/StyledButton';
import ProductCategoryTypeIcon from '../../../components/icons/ProductCategoryTypeIcon';
import LoadingSkeleton from '../../../components/loadingSpinner/LoadingSkeleton';
import MenuTabs from '../../../components/menu/MenuTabs';
import OrderItemVariationsModal from '../../../components/modal/OrderItemVariationsModal';
import usePopularProducts from '../../../datastore/usePopularProducts';
import useProducts from '../../../datastore/useProducts';
import useSites from '../../../datastore/useSites';
import useSurcharge from '../../../datastore/useSurcharge';
import useAlert from '../../../hooks/useAlert';
import useRequest from '../../../hooks/useRequest';
import useSelectedSite, {
  SelectedSiteType,
} from '../../../hooks/useSelectedSite';
import CurrentWaitTime from '../components/CurrentWaitTime';
import OrderLockoutBanner from '../components/OrderLockoutBanner';
import { sortProducts } from '../../../common/productFunctions';
import useAuthStore from '../../../datastore/useAuth';
import OrderAgainCarousel from './components/OrderAgainCarousel';
import PopularProductsCarousel from './components/PopularProductsCarousel';
import ProductSearch from './components/ProductSearch';
import SiteSelector from './components/SiteSelector';

const emptyOrder: Partial<Order> = {
  siteId: 0,
  orderItems: [],
};

function filterProducts(
  products: Product[] = [],
  selectedSiteId: number,
  category: ProductCategoryType,
  searchTerm: string = '',
): Product[] {
  const lowerSearchTerm = searchTerm.toLowerCase();
  return products.filter(
    (p) =>
      p.isActive === true &&
      p.productCategory.name === `${category}` &&
      selectedSiteId &&
      p.siteId === selectedSiteId &&
      (searchTerm ? p.name.toLowerCase().includes(lowerSearchTerm) : true),
  );
}


export default function CustomerHomePage() {
  const { currentUser: currentCustomer } = useAuthStore();
  const { surcharge } = useSurcharge()
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const location = useLocation();
  const defaultOrder = location.state ?? { ...emptyOrder, surchargeFixed: surcharge?.fixed ?? null, surchargePercentage: surcharge?.percentage ?? null };

  const [selectedSiteId, setSelectedSiteId] = useSelectedSite(
    (state: SelectedSiteType) => [
      state.selectedSiteId,
      state.setSelectedSiteId,
    ],
  );
  const [selectedCategory, setSelectedCategory] = useState<ProductCategoryType>(
    ProductCategoryType.HotCoffee,
  );
  const [showProductSearch, setShowProductSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [order, setOrder] = useState<Partial<Order>>(defaultOrder);
  const [newOrderItem, setNewOrderItem] = useState<OrderItem | null>(null);

  useEffect(() => {
    if (surcharge) {
      const { fixed, percentage } = surcharge
      setOrder(o => ({ ...o, surchargeFixed: fixed, surchargePercentage: percentage }));

    }
  }, [surcharge]);

  const { sites, loading: sitesLoading } = useSites();
  const {
    products = [],
    loading: productsLoading,
    fetchProducts,
  } = useProducts();
  const { popularProducts, loading: popularProductsLoading } =
    usePopularProducts();
  const { data: orderHistory, loading: orderHistoryLoading } = useRequest<
    OrderSummary[]
  >(getOrderHistory, [], !currentCustomer);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const loading = sitesLoading || productsLoading || popularProductsLoading;

  const viewCart = () => {
    navigate('/checkout', { state: order });
  };

  const onSearchClicked = () => setShowProductSearch(true);
  const closeSearch = () => setShowProductSearch(false);

  const onSiteSelected = (site: Site | null): void => {
    if (site) {
      setSelectedSiteId(site.id);
      LocalStorageSvc.save(SELECTED_SITE_KEY, site.id);
    }
  };

  const onProductClicked = (product: Product): void => {
    setNewOrderItem(mapProductToOrderItem(product));
  };

  const onPopularProductItemClicked = (item: PopularProduct): void => {
    if (item.product) {
      onProductClicked(item.product);
    }
  };

  const closeAddItemModal = (orderItem: OrderItem | null) => {
    if (orderItem) {
      const updatedOrder = { ...order };
      if (!updatedOrder.siteId && selectedSiteId) {
        updatedOrder.siteId = selectedSiteId;
      }
      updatedOrder.orderItems = [...(updatedOrder.orderItems ?? []), orderItem];
      setOrder(updatedOrder);
    }
    setNewOrderItem(null);
  };

  const handleOrderAgainClicked = (orderSummary: OrderSummary) => {
    try {
      const newOrder: Partial<Order> = mapOrderSummaryToOrder(
        orderSummary,
        products || [],
      );
      setOrder(newOrder);
    } catch (err) {
      const e = err as Error;
      showAlert(`Order again failed: ${e.message}`, 'error');
    }
  };

  const filteredProducts = filterProducts(
    products || [],
    selectedSiteId || 0,
    selectedCategory,
    searchTerm,
  );

  const sortedFilteredProducts = sortProducts(filteredProducts);

  const showOrderAgain: boolean =
    !!currentCustomer && (orderHistoryLoading || !!orderHistory?.length);

  return (
    <Stack sx={{ flex: 1 }}>
      <OrderLockoutBanner />
      <LoadingSkeleton isLoading={loading} width="100%" height={'3rem'}>
        <SiteSelector
          sites={sites || []}
          onSiteSelected={onSiteSelected}
          selectedSiteId={selectedSiteId || 0}
        />
        {selectedSiteId && <CurrentWaitTime />}
      </LoadingSkeleton>

      <Stack
        sx={{
          background: 'linear-gradient(to bottom, #F5F1EB, #EFEBE4)',
          flex: 0,
        }}
      >
        <Stack
          flexDirection="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: 1.5,
            flex: 0,
          }}
        >
          <Typography variant="h3" sx={{ marginLeft: 2, fontWeight: 'bold' }}>
            Have a gander!
          </Typography>
        </Stack>
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Popular Products
        </Typography>
        <PopularProductsCarousel
          {...{
            products: products || [],
            selectedSiteId: selectedSiteId || 0,
            popularProducts:
              popularProducts?.filter((p) => p.isActive) || [],
            isLoading: loading,
            onItemClick: onPopularProductItemClicked,
          }}
        />
        {showOrderAgain && (
          <>
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
              Order Again
            </Typography>
            <OrderAgainCarousel
              history={orderHistory || []}
              loading={orderHistoryLoading}
              onItemClick={handleOrderAgainClicked}
            />
          </>
        )}
      </Stack>

      <Stack sx={{ flex: 1 }}>
        <Stack
          flexDirection="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            paddingX: 2,
          }}
        >
          <Typography variant="h6">All products</Typography>
          {searchTerm && (
            <Chip
              label={searchTerm}
              onDelete={() => setSearchTerm('')}
              sx={{ marginLeft: 'auto' }}
            />
          )}
          <IconButton
            aria-label="search"
            onClick={onSearchClicked}
            sx={{ marginLeft: 'auto' }}
          >
            <SearchIcon />
          </IconButton>
        </Stack>
        <Stack flexDirection="row" sx={{ overflowX: 'auto' }}>
          <MenuTabs
            selectedCategory={selectedCategory}
            handleCategoryChange={(_, category) =>
              setSelectedCategory(category)
            }
          />
        </Stack>
        {loading ? (
          <Typography variant="body2" sx={{ paddingX: 2 }}>
            Loading...
          </Typography>
        ) : !selectedSiteId ? (
          <Typography variant="body2" sx={{ paddingX: 2 }}>
            No store selected
          </Typography>
        ) : !sortedFilteredProducts.length ? (
          <Typography variant="body2" sx={{ paddingX: 2 }}>
            No products found
          </Typography>
        ) : (
          <List>
            {sortedFilteredProducts.map((product) => (
              <ListItem
                key={product.id}
                secondaryAction={
                  <IconButton onClick={() => onProductClicked(product)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                }
                divider
                sx={{ paddingY: 0 }}
              >
                <ListItemIcon>
                  <ProductCategoryTypeIcon category={selectedCategory} />
                </ListItemIcon>
                <ListItemText
                  primary={product.name}
                  secondary={`$${formatCurrency(productBasePrice(product))}`}
                />
                <ListItemText
                  secondary={`Qty: ${order.orderItems?.filter((f) => f.productId === product.id)?.length ?? '0'}`}
                  sx={{
                    marginLeft: 'auto',
                    marginRight: 2,
                    textAlign: 'right',
                  }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Stack>

      {showProductSearch && (
        <ProductSearch
          handleClose={closeSearch}
          onSearchChanged={(val) => setSearchTerm(val)}
          searchText={searchTerm}
        />
      )}
      {newOrderItem && (
        <OrderItemVariationsModal
          item={newOrderItem}
          onClose={closeAddItemModal}
        />
      )}
      <Stack
        flexDirection="row"
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          width: '100%',
          justifyContent: 'center',
          position: 'sticky',
          bottom: 10,
          height: 45,
        }}
      >
        <StyledButton
          variant="contained"
          onClick={viewCart}
          disabled={!order?.orderItems?.length}
          sx={{
            marginLeft: 2,
            marginRight: 2,
            width: '100%',
            height: 45,
            maxWidth: 500,
          }}
        >
          View cart
          {!!order?.orderItems?.length && ` (${order?.orderItems?.length})`}
        </StyledButton>
      </Stack>
    </Stack>
  );
}
