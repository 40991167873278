import AssessmentIcon from '@mui/icons-material/Assessment';
import GradeIcon from '@mui/icons-material/Grade';
import HomeIcon from '@mui/icons-material/Home';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TapasIcon from '@mui/icons-material/Tapas';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import icon from '../../assets/circleIcon.png';
import build from '../../common/buildEnv';
import useAuthStore from '../../datastore/useAuth.tsx';
import {
  navbarAdminItems,
  navbarEndItems,
  navbarListItemsIconEnum,
  navbarListItemsTextEnum,
  navbarMainItems,
} from './navbarData';
import { NavbarItem } from './navbarItem';

const { version, env } = build;

function getIcon(icon: unknown, isActive: boolean, theme: Theme) {
  const color = isActive
    ? 'white'
    : (theme?.palette?.primary?.main ?? '#00846D');
  switch (icon) {
    //Staff
    case navbarListItemsIconEnum.DASHBOARD:
      return <HomeIcon sx={{ color }} />;
    case navbarListItemsIconEnum.WALK_UP:
      return <TransferWithinAStationIcon sx={{ color }} />;
    case navbarListItemsIconEnum.POS:
      return <PointOfSaleIcon sx={{ color }} />;
    case navbarListItemsIconEnum.ORDER:
      return <ReceiptIcon sx={{ color }} />;
    //Admin
    case navbarListItemsIconEnum.POPULAR:
      return <GradeIcon sx={{ color }} />;
    case navbarListItemsIconEnum.ACCOUNTS:
      return <ManageAccountsIcon sx={{ color }} />;
    case navbarListItemsIconEnum.CUSTOMERS:
      return <PersonIcon sx={{ color }} />;
    case navbarListItemsIconEnum.PRODUCTS:
      return <ShoppingCartIcon sx={{ color }} />;
    case navbarListItemsIconEnum.COMPONENTS:
      return <TapasIcon sx={{ color }} />;
    case navbarListItemsIconEnum.COMPONENT_TYPES:
      return <LocalDiningIcon sx={{ color }} />;
    case navbarListItemsIconEnum.REPORTS:
      return <AssessmentIcon sx={{ color }} />;
    //General
    case navbarListItemsIconEnum.SETTINGS:
      return <SettingsIcon sx={{ color }} />;
    case navbarListItemsIconEnum.LOG_OUT:
      return <LogoutIcon sx={{ color }} />;
    default:
      return <OpenInNewIcon sx={{ color }} />;
  }
}

export default function SideNavbar() {
  const navigate = useNavigate();
  const { logout, role } = useAuthStore()
  const theme = useTheme();
  const location = useLocation();

  function handleSelect(path: string, text: string) {
    if (text === navbarListItemsTextEnum.LOG_OUT) {
      logout();
      navigate('/')
    } else {
      navigate(path);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        width: '5em',
        backgroundColor: theme.palette.common.deepblue,
      }}
    >
      <Box>
        <img
          src={icon}
          alt="Logo for TPGE"
          style={{
            width: '5em',
          }}
        />
        <List sx={{ paddingLeft: '1.5em' }}>
          <>
            {navbarMainItems.map((item, index) => {
              const isActive = location.pathname === item.path;
              return (
                <Tooltip
                  key={index}
                  title={item.tooltipText}
                  placement="right"
                  arrow
                  enterTouchDelay={0}
                >
                  <Box>
                    <NavbarItem
                      text={item.text}
                      icon={getIcon(item.icon, isActive, theme)}
                      path={item.path}
                      handleSelect={() => handleSelect(item.path, item.text)}
                    />
                  </Box>
                </Tooltip>
              );
            })}
          </>
          {role === 'AdminStaff' && (
            <>
              {navbarAdminItems.map((item, index) => {
                const isActive = location.pathname === item.path;
                return (
                  <Tooltip
                    key={index}
                    title={item.tooltipText}
                    placement="right"
                    arrow
                    enterTouchDelay={0}
                  >
                    <Box>
                      <NavbarItem
                        text={item.text}
                        icon={getIcon(item.icon, isActive, theme)}
                        path={item.path}
                        handleSelect={() => handleSelect(item.path, item.text)}
                      />
                    </Box>
                  </Tooltip>
                );
              })}
            </>
          )}
        </List>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 1,
            px: 0.5,
          }}
        >
          <Typography
            color="grey.500"
            sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}
          >
            v{version ?? ' n/a'}
          </Typography>
          <Typography color="grey.500" sx={{ fontSize: '0.8rem' }}>
            {env}
          </Typography>
        </Box>
        <List sx={{ paddingLeft: '1.5em' }}>
          {navbarEndItems.map((item, index) => {
            const isActive = location.pathname === item.path;
            return (
              <Tooltip
                key={index}
                title={item.tooltipText}
                placement="right"
                arrow
                enterTouchDelay={0}
              >
                <Box>
                  <NavbarItem
                    text={item.text}
                    icon={getIcon(item.icon, isActive, theme)}
                    path={item.path}
                    handleSelect={() => handleSelect(item.path, item.text)}
                  />
                </Box>
              </Tooltip>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
