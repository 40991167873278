import { create } from 'zustand'
import { postRequest } from '../api/requests'
import { updateStation } from '../api/staff'
import { CustomerProfileEndpoint, CustomerRegisterEndpoint, ForgotPasswordEndpoint, LoginEndpoint, LogoutEndpoint, PasswordResetEndpoint, RefreshTokenEndpoint } from '../common/endpoints'
import LocalStorageSvc, { persistUser, REFRESH_TOKEN_KEY, USER_DATA_KEY, UserType } from '../common/localStorageSvc'
import { Customer, ServerResponse, Staff, Station } from '../common/types'
import useAlert from '../hooks/useAlert'

type LoginModalMode = 'login' | 'register';
type AuthType = {
    currentUser: UserType | null
    role: 'AdminStaff' | 'GeneralStaff' | 'customer' | null
    showAccountModal: LoginModalMode | null;
    setShowAccountModal: (show: LoginModalMode | null) => void;
    login: (email: string, password: string) => Promise<UserType | null>
    logout: () => Promise<void>
    forgotPassword: (email: string) => Promise<void>
    resetPassword: (email: string, password: string, token: string) => Promise<void>
    refreshToken: () => Promise<void>
    updateCustomerProfile: (name: string, phoneNumber: string) => Promise<Customer | null>
    updateStaffStation: (station: Station) => Promise<void>
    register: (
        email: string,
        password: string,
        name: string,
        phone: string,
    ) => Promise<Customer | null>;
    init: () => void
}

const useAuthStore = create<AuthType>((set, get) => {
    const roles = (jwt: string) => {
        const payload = jwt.split('.')[1]
        const decoded = JSON.parse(atob(payload))
        const roleKey = Object.keys(decoded).find(f => f.endsWith('claims/role'))
        return roleKey ? decoded[roleKey] : null
    }

    const setUserData = (user: UserType) => {
        set({ currentUser: user, role: roles(user.jwtToken) })
        persistUser(user)
    }
    const clearUserData = () => {
        set({ currentUser: null, role: null })
        persistUser(null)
    }

    return {
        currentUser: null,
        role: null,
        showAccountModal: null,

        setShowAccountModal: (show: LoginModalMode | null): void => {
            set({ showAccountModal: show })
        },

        login: async (email: string, password: string): Promise<UserType | null> => {
            console.log(email, password, LoginEndpoint)
            const result = await postRequest(LoginEndpoint, { user: email, password })
            if (result.status === 200 && result.data) {
                setUserData(result.data)
                return result.data
            } else if (result.status === 400) {
                useAlert.getState().showAlert('Email or password is incorrect', 'error')
            } else {
                console.error(result.data || 'An error occurred')
                useAlert.getState().showAlert('An error occurred', 'error')
            }
            return null
        },

        logout: async (): Promise<void> => {
            await postRequest(LogoutEndpoint, {})
            clearUserData()
        },

        forgotPassword: async (email: string): Promise<void> => {
            try {
                await postRequest(ForgotPasswordEndpoint, { email })
            } catch (err) {
                console.error(err)
            }
        },

        resetPassword: async (email: string, password: string, token: string): Promise<void> => {
            const result = await postRequest(PasswordResetEndpoint, { email, password, token })
            if (result.status !== 200) {
                console.error(result.data || 'An error occurred')
                throw new Error(result.data || 'An error occurred')
            }
        },

        init: (): void => {
            const loadedUser = LocalStorageSvc.load<UserType>(USER_DATA_KEY)
            if (loadedUser) {
                setUserData(loadedUser)
            } else {
                clearUserData()
            }
        },

        refreshToken: async (): Promise<void> => {
            const refreshToken = LocalStorageSvc.load<string>(REFRESH_TOKEN_KEY)
            if (refreshToken) {
                try {
                    const result = await postRequest(RefreshTokenEndpoint, { refreshToken })
                    if (result.status === 200 && result.data) {
                        const loadedUser = LocalStorageSvc.load<UserType>(USER_DATA_KEY)
                        if (loadedUser) {
                            const clone = { ...loadedUser }
                            clone.jwtToken = result.data.token
                            clone.refreshToken = result.data.refreshToken
                            setUserData(clone)
                        } else {
                            clearUserData()
                            useAlert.getState().showAlert('your session has expired, please login again', 'error')
                        }
                    }
                } catch (err) {
                    console.error(err)
                    const msg = (err as { message?: string })?.message || 'An error occurred'
                    console.error(msg)
                    useAlert.getState().showAlert(msg, 'error')
                }
            }
        },

        register: async (
            email: string,
            password: string,
            name: string,
            phone: string,
        ): Promise<Customer | null> => {
            try {
                const results: ServerResponse<Customer> = await postRequest(
                    CustomerRegisterEndpoint,
                    {
                        email,
                        password,
                        name,
                        phone,
                    },
                );

                if (results.status === 200 && results.data) {
                    const clone = { ...results.data };
                    setUserData(clone);
                    return clone;
                } else {
                    return null;
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        },

        updateCustomerProfile: async (name: string, phoneNumber: string): Promise<Customer | null> => {
            try {
                const results: ServerResponse<Customer> = await postRequest(
                    `${CustomerProfileEndpoint}/update-profile`,
                    {
                        name,
                        phone: phoneNumber,
                    },
                );
                if (results.status === 200) {
                    const clone = results.data as Customer;
                    setUserData(clone);
                    useAlert.getState().showAlert('Profile updated successfully', 'success')
                    return clone
                } else {
                    useAlert.getState().showAlert('Profile update failed', 'error')
                    return null
                }
            } catch (error) {
                useAlert.getState().showAlert('Profile update failed', 'error')
                console.error(error);
                return null
            }
        },

        updateStaffStation: async (station: Station): Promise<void> => {
            const currentUser = get().currentUser as Staff
            const result = await updateStation(
                currentUser?.email,
                station.id,
            );
            if (result.status === 200) {
                const updatedUser = {
                    ...currentUser,
                    stationId: station.id,
                    stationName: station.name,
                };
                setUserData(updatedUser);
                useAlert.getState().showAlert('Station updated', 'success');
            } else {
                useAlert.getState().showAlert('Update station failed', 'error');
            }
        }
    }
})

useAuthStore.getState().init()

export default useAuthStore