import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import icon from '../../../assets/icon_transparent.png';
import { passwordName, userName } from '../../../common/constants';
import {
  confirmPasswordRules,
  passwordRules,
} from '../../../common/formValidationRules';
import LoginForm from '../../../components/account/LoginForm';
import RegisterForm from '../../../components/account/RegisterForm';
import useAlert from '../../../hooks/useAlert';
import useAuthStore from '../../../datastore/useAuth';
import { isStaff } from '../../../common/typeAssertionFunctions';

export default function CustomerLoginComponent() {
  const { showAccountModal, setShowAccountModal, login, register } = useAuthStore()
  const { showAlert } = useAlert();
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
    reset,
  } = useForm();

  const password = watch(passwordName, '');

  const submitForm = () => {
    showAccountModal && showAccountModal === 'login'
      ? handleSubmit(onLogin)()
      : handleSubmit(onRegister)();
  };

  const onLogin = async (form: { [key: string]: string }) => {
    try {
      setLoading(true);
      console.log('calling login from component.onLogin')
      const user = await login(form[userName], form[passwordName]);
      if (user) {
        setShowAccountModal(null);
        if (isStaff(user)) {
          navigate('/staff/home')
        }
      }
    } catch {
      showAlert('Log in failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  const onRegister = async (form: { [key: string]: string }) => {
    try {
      setLoading(true);
      const user = await register(
        form[userName],
        form[passwordName],
        form['Name'],
        form['Phone'],
      );
      if (user) {
        showAlert('Thanks!', 'success');
        setShowAccountModal(null);
      } else {
        showAlert('Register account failed :(', 'error');
      }
    } catch {
      showAlert('Register account failed :(', 'error');
    } finally {
      setLoading(false);
    }
  };

  const switchMode = () => {
    setShowAccountModal(showAccountModal === 'login' ? 'register' : 'login');
    reset();
    clearErrors(Object.keys(errors));
  };

  const modeSwitchLabel =
    showAccountModal === 'login'
      ? 'Don\'t have an account?'
      : 'Already have an account?';
  const modeSwitchTitle =
    showAccountModal === 'login' ? 'Create Account' : 'Log in';
  const modeTitle = showAccountModal === 'login' ? 'Log in' : 'Create Account';

  if (!showAccountModal) {
    return null;
  }

  const passwordValidationRules =
    showAccountModal === 'login' ? { required: true } : passwordRules();
  return (
    <>
      <DialogTitle sx={{ width: '100%' }}>
        <Stack spacing={1.5} sx={{ alignItems: 'center' }}>
          <img
            src={icon}
            alt="Logo for TPGE"
            style={{ width: '100%', maxWidth: 75 }}
          />

          <Typography
            variant="h6"
            color="primary"
            textAlign="center"
            sx={{ marginTop: -6 }}
          >
            {modeTitle}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingX: 2,
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexGrow: 1,
          width: '90%',
          maxWidth: 375,
        }}
      >
        <Stack spacing={1}>
          <LoginForm
            control={control}
            errors={errors}
            loading={loading}
            showForgotPassword={showAccountModal === 'login'}
            passwordValidationRules={passwordValidationRules}
          />

          {showAccountModal === 'register' && (
            <RegisterForm
              control={control}
              errors={errors}
              loading={loading}
              passwordValidationRules={confirmPasswordRules(password)}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ width: '90%', maxWidth: 375 }}>
        <Stack spacing={1} sx={{ paddingTop: '0.5em', width: '100%' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={submitForm}
            sx={{ padding: '1em 0' }}
            disabled={loading}
          >
            {showAccountModal === 'login' ? 'Log In' : 'Sign up'}
          </Button>
          <Divider />
          <Stack
            flexDirection="row"
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Typography variant="caption">{modeSwitchLabel}</Typography>
            <Button
              variant="text"
              size="small"
              onClick={switchMode}
              disabled={loading}
            >
              {modeSwitchTitle}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </>
  );
}
