import NotificationsIcon from '@mui/icons-material/Notifications';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns/format';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import orderApi from '../../../api/order';
import { PaymentType } from '../../../common/enums';
import { calculateStaffTotalPrice } from '../../../common/moneyFunctions';
import { calculateTotalPlusSurcharge } from '../../../common/surchargeFunctions';
import { Order, StaffOrder } from '../../../common/types';
import InfoCard from '../../../components/card/InfoCard';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import useOrders from '../../../datastore/useOrders';
import useAlert from '../../../hooks/useAlert';
import PaymentModal from './components/PaymentModal';
import PosHeader from './components/PosHeader';
import ReceiptModal from './components/ReceiptModal';

const ITEMS_PER_PAGE = 200;

export default function PosPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const walkUpOrder: Order | null = location.state?.order ?? null;
  const [paymentOrderId, setPaymentOrderId] = useState<number | null>(
    walkUpOrder?.id ? walkUpOrder.id : null,
  );
  const theme = useTheme();
  const { orders, fetchOrders, startPolling, stopPolling, params: getOrdersParams, resetFilters } = useOrders();
  const [startDate, setStartDate] = useState<Date | null>(getOrdersParams?.startDate ?? new Date());
  const [endDate, setEndDate] = useState<Date | null>(getOrdersParams?.endDate ?? null);
  const [searchCustomer, setSearchCustomer] = useState('');
  const [hidePaid, setHidePaid] = useState(!walkUpOrder);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [removeOrderId, setRemoveOrderId] = useState<number | null>(null);
  const [invoiceOrderId, setInvoiceOrderId] = useState<number | null>(null);
  const { showAlert } = useAlert();

  useEffect(() => {
    startPolling({ initialInterval: 5000, maxInterval: 15000 })
    return () => stopPolling()
  }, [])

  const invoiceOrder = orders?.find((order) => order.id === invoiceOrderId);

  const walkUpReturnRoute: string = location.state?.returnRoute ?? '';

  async function handleMarkAsPaid(orderId: number, paymentType: PaymentType) {
    if (!orderId) {
      showAlert('Failed to mark order as paid.', 'error');
      return;
    }
    const order = orders.find((order) => order.id === orderId);
    if (!order) {
      showAlert('Failed to mark order as paid.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const result = await orderApi.startStopFinishOrder({
        id: order.id,
        startTime: null,
        finishTime: null,
        orderItems: order.orderItems.map((m) => ({
          id: m.id,
          status: m.status,
        })),
        softDelete: false,
        paymentType,
      });

      if (result.status !== 200) {
        showAlert('Failed to mark order as paid.', 'error');
        return;
      }
      fetchOrders(getOrdersParams);
      showAlert('Order marked as paid.', 'success');
      if (walkUpReturnRoute) {
        navigate(walkUpReturnRoute);
      }
    } catch (err) {
      console.error(err);
      showAlert('Failed to mark order as paid.', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    setCurrentPage(1);
    fetchOrders({
      includeHistory: true,
      startDate: date,
      endDate,
    });
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setCurrentPage(1);
    fetchOrders({
      includeHistory: true,
      startDate,
      endDate: date,
    });
  };

  const handleResetFilters = () => {
    setSearchCustomer('');
    setStartDate(null);
    setEndDate(null);
    setHidePaid(true);
    resetFilters();
    setCurrentPage(1);
    fetchOrders();
  }

  async function handleRemove(orderId: number) {
    if (!orderId) {
      showAlert('Failed to remove order.', 'error');
      return;
    }
    const order = orders.find((order) => order.id === orderId);
    if (!order) {
      showAlert('Failed to remove order.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const result = await orderApi.startStopFinishOrder({
        ...order,
        softDelete: true,
      });
      if (result.status !== 200) {
        showAlert(
          'Failed to remove order, refresh page and try again.',
          'error',
        );
        return;
      }
      fetchOrders({
        includeHistory: true,
        startDate,
        endDate,
      });
      showAlert('Order removed.', 'success');
    } catch (err) {
      console.error(err);
      showAlert('Failed to remove order, refresh page and try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const filteredOrders = orders?.filter((order) => {
    const matchesPaidFilter = !hidePaid || !order.isPaid;
    const matchesSearchFilter =
      !searchCustomer ||
      order.customerName?.toLowerCase().includes(searchCustomer.toLowerCase());
    return matchesPaidFilter && matchesSearchFilter;
  });

  const totalNumberOfPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE,
  );

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  const paymentOrder = orders?.find((f) => f.id === paymentOrderId);
  const removeOrder = orders?.find((f) => f.id === removeOrderId);

  return (
    <>
      <Stack sx={{ height: '100vh', flexDirection: 'column' }}>
        <Box sx={{ p: 2, backgroundColor: theme.palette.common.grey }}>
          <PosHeader
            searchCustomer={searchCustomer}
            setSearchCustomer={setSearchCustomer}
            hidePaid={hidePaid}
            setHidePaid={setHidePaid}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            resetFilters={handleResetFilters}
          />
        </Box>
        <Divider />

        <Stack
          sx={{ flexGrow: 1, overflow: 'hidden', flexDirection: 'column' }}
        >
          {paginatedOrders.length === 0 ? (
            <InfoCard
              Icon={NotificationsIcon}
              title="No Results Found"
              subtitle="Try refreshing your page or check back later."
            />
          ) : (
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              <TableContainer>
                <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight="bold">Customer Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Created Time</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Start Time</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Finish Time</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Is Paid</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Payment Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">Total Price</Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedOrders?.map((order: StaffOrder) => {
                      const orderTotal = calculateStaffTotalPrice(order.orderItems)
                      const total = order.surchargeFixed && order.surchargePercentage ? calculateTotalPlusSurcharge(orderTotal, order.surchargeFixed, order.surchargePercentage) || orderTotal : orderTotal
                      return (
                        <TableRow
                          key={order.id}
                          onClick={() => navigate(`/staff/pos/${order.id}`)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                          }}
                        >
                          <TableCell width="20%">{order.customerName}</TableCell>
                          <TableCell width="10%">
                            <Typography variant="caption">
                              {order.createdAt
                                ? format(
                                  new Date(order.createdAt),
                                  'dd/MM/yy HH:mm a',
                                )
                                : ''}
                            </Typography>
                          </TableCell>
                          <TableCell width="10%">
                            <Typography variant="caption">
                              {order.startTime
                                ? format(
                                  new Date(order.startTime),
                                  'dd/MM/yy HH:mm a',
                                )
                                : ''}
                            </Typography>
                          </TableCell>
                          <TableCell width="10%">
                            <Typography variant="caption">
                              {order.finishTime
                                ? format(
                                  new Date(order.finishTime),
                                  'dd/MM/yy HH:mm a',
                                )
                                : ''}
                            </Typography>
                          </TableCell>
                          <TableCell width="10%">
                            {order.isPaid ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell width="15%">
                            {order.paymentType || 'Not Paid'}
                          </TableCell>
                          <TableCell width="10%">
                            ${total.toFixed(2)}
                          </TableCell>
                          <TableCell width="10%">
                            {
                              order.isPaid ? (
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    setInvoiceOrderId(order.id)
                                  }}
                                >
                                  Send Invoice
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{ margin: 0, whiteSpace: 'nowrap' }}
                                  disabled={isLoading}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsLoading(true);
                                    setPaymentOrderId(order.id);
                                  }}
                                >
                                  Mark as Paid
                                </Button>
                              )
                            }
                          </TableCell>
                          <TableCell width="10%">
                            <Button
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{ margin: 0, whiteSpace: 'nowrap' }}
                              disabled={isLoading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsLoading(true);
                                setRemoveOrderId(order.id);
                              }}
                            >
                              Remove Order
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Pagination
                count={totalNumberOfPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>

      {removeOrderId && (
        <ConfirmModal
          onClose={() => {
            setIsLoading(false);
            setRemoveOrderId(null);
          }}
          title={`Remove order for ${removeOrder?.customerName?.toUpperCase()}`}
          content="Are you sure you want to remove this item?"
          onConfirm={() => {
            handleRemove(removeOrderId);
            setRemoveOrderId(null);
            setIsLoading(false);
          }}
        />
      )}
      {paymentOrderId && (
        <PaymentModal
          onClose={() => {
            setIsLoading(false);
            setPaymentOrderId(null);
          }}
          open={!!paymentOrderId}
          title={`Payment Method for ${paymentOrder?.customerName?.toUpperCase()}`}
          content="Please select the payment method for this order."
          onConfirm={(paymentType: PaymentType) => {
            handleMarkAsPaid(paymentOrderId, paymentType);
            setPaymentOrderId(null);
            setIsLoading(false);
          }}
        />
      )}
      {invoiceOrder && (
        <ReceiptModal
          onClose={() => {
            setInvoiceOrderId(null);
          }}
          order={invoiceOrder}
        />
      )}

    </>
  );
}
