import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { hexWithAlpha } from '../../../theme/theme';

interface TimeButtonProps extends ButtonProps {
    isTimeSelect?: boolean;
}

const StyledTimeButton = styled(Button)<TimeButtonProps>(({ theme, isTimeSelect = false }) => ({
    '&.MuiButton-outlined': {
        border: `1px solid ${theme.palette.common.babyBlue}`,
        color: theme.palette.common.babyBlue,
        ...(isTimeSelect && {
            backgroundColor: hexWithAlpha(theme.palette.common.babyBlue, 0.25),
        }),
    },
    '&.Mui-disabled': {
        backgroundColor: hexWithAlpha(theme.palette.common.babyBlue, 0.25),
        opacity: 1,
    },
}));

export default StyledTimeButton