import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  formatCurrency,
  productBasePrice,
} from '../../../../common/moneyFunctions';
import { PopularProduct, Product } from '../../../../common/types';
import ProductCategoryTypeIcon from '../../../../components/icons/ProductCategoryTypeIcon';

type PopularProductsCarouselProps = {
  popularProducts: PopularProduct[];
  products: Product[];
  selectedSiteId: number;
  isLoading: boolean;
  onItemClick: (item: PopularProduct) => void;
};

export default function PopularProductsCarousel({
  popularProducts,
  products,
  isLoading,
  selectedSiteId,
  onItemClick,
}: PopularProductsCarouselProps) {
  const popularProductsForSite = popularProducts
    .map((pp) => {
      const matchingProduct = products.find((f) => f.id === pp.productId);
      return { ...pp, product: matchingProduct };
    })
    .filter((f) => f.siteId === selectedSiteId && f.product)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .slice(0, 5);

  if (!isLoading && !selectedSiteId) {
    return (
      <Typography variant="body2" sx={{ paddingX: 2 }}>
        No store selected
      </Typography>
    );
  }
  if (isLoading) {
    return (
      <Stack sx={{ overflowX: 'auto', width: '100%', marginBottom: 1 }}>
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            minWidth: 'fit-content',
            paddingBottom: 1,
            marginBottom: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Skeleton
            width={125}
            height={75}
            sx={{ marginLeft: '1rem' }}
            variant="rounded"
          />
          <Skeleton width={125} height={75} variant="rounded" />
          <Skeleton width={125} height={75} variant="rounded" />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack sx={{ overflowX: 'auto', width: '100%', marginBottom: 1 }}>
      <Stack
        flexDirection="row"
        sx={{ minWidth: 'fit-content', paddingBottom: 1 }}
      >
        {popularProductsForSite.map((item, i, arr) => (
          <Paper
            role="button"
            key={item.id}
            elevation={0}
            sx={{
              width: 125,
              borderRadius: 2,
              marginLeft: i === 0 ? 2 : 1,
              marginRight: i === arr.length - 1 ? 2 : 0,
              padding: 1,
            }}
            onClick={() => onItemClick(item)}
          >
            <Stack justifyContent="flex-start" sx={{ height: '100%' }}>
              <Stack
                flexDirection="row"
                sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
              >
                <ProductCategoryTypeIcon
                  category={item.product?.productCategory?.name}
                />
                <Typography
                  variant="caption"
                  fontSize={10}
                  sx={{ marginLeft: 'auto' }}
                >
                  ${formatCurrency(productBasePrice(item.product!))}
                </Typography>
              </Stack>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {item.product?.name}
              </Typography>
            </Stack>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
}
