import { Staff, Customer } from './types'

export const USER_DATA_KEY = 'userData';
export const JWT_KEY = 'jwtToken';
export const SELECTED_SITE_KEY = 'SELECTED_SITE';
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';


export type UserType = Staff | Customer

export default class LocalStorageSvc {
  public static save = <T>(key: string, value: T): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  public static load = <T>(key: string): T | null => {
    const json = localStorage.getItem(key);
    if (!json) {
      return null;
    }
    return JSON.parse(json) as T;
  };
  public static remove = (key: string): void => {
    localStorage.removeItem(key);
  };
}

export const persistUser = (user: UserType | null): void => {
  if (!user) {
    LocalStorageSvc.remove(JWT_KEY)
    LocalStorageSvc.remove(USER_DATA_KEY)
    LocalStorageSvc.remove(REFRESH_TOKEN_KEY)
  } else {
    LocalStorageSvc.save(JWT_KEY, user.jwtToken)
    LocalStorageSvc.save(USER_DATA_KEY, user)
    LocalStorageSvc.save(REFRESH_TOKEN_KEY, user.refreshToken)
  }
}
