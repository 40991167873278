import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import productApi from '../../../../api/product.tsx';
import productVariantsApi from '../../../../api/productVariants.tsx';
import { hasAdminRole } from '../../../../common/roleFunctions.tsx';
import {
  MutateProductVariant,
  Product,
  ProductVariant,
} from '../../../../common/types.tsx';
import useAuthStore from '../../../../datastore/useAuth.tsx';
import useComponents from '../../../../datastore/useComponents.tsx';
import useComponentTypes from '../../../../datastore/useComponentTypes.tsx';
import useAlert from '../../../../hooks/useAlert.tsx';
import useRequest from '../../../../hooks/useRequest.tsx';
import { AdminHeader } from '../../components/AdminHeader.tsx';
import ProductVariantModal from './components/ProductVariationModal.tsx';
import { VariationFilter } from './components/VariationFilter.tsx';
import { VariationTable } from './components/VariationTable.tsx';

const variationsPerPage = 200;

const filteredProductVariants = (
  product: Product | null,
  showDisabled: boolean,
  filterText: string,
  selectedComponentTypeId: number | undefined,
): ProductVariant[] => {
  if (!product?.productVariants?.length) {
    return [];
  }
  return product.productVariants.filter(
    (variant) =>
      (showDisabled || variant.isActive) &&
      (filterText?.length > 0
        ? variant.name.toLowerCase().includes(filterText.toLowerCase())
        : true) &&
      (selectedComponentTypeId
        ? variant.component?.componentTypeId === selectedComponentTypeId
        : true),
  );
};

const newProductVariant = (
  product: Product | undefined,
): MutateProductVariant | null => {
  if (!product) {
    return null;
  }
  const tmp: MutateProductVariant = {
    id: 0,
    name: '',
    productId: product.id,
    isCore: false,
    componentId: 0,
    isActive: true,
    productVariantSizeVolumes: product.productSizes
      .map((size) => ({
        id: 0,
        productSizeId: size.id,
        sizeName: size.sizeName,
        volume: 0,
        priceAdjustment: 0,
        isActive: size.isActive,
      }))
      .sort((a, b) => b.sizeName.localeCompare(a.sizeName)),
  };
  return tmp;
};

const mapProductVariantToMutateProductVariant = (
  productVariant: ProductVariant,
): MutateProductVariant => {
  return {
    id: productVariant.id,
    name: productVariant.name,
    productId: productVariant.productId,
    isCore: productVariant.isCore,
    componentId: productVariant.componentId,
    isActive: productVariant.isActive,
    productVariantSizeVolumes: productVariant.productVariantSizeVolumes
      .map((p) => ({
        id: p.id,
        productSizeId: p.productSizeId,
        sizeName: p.sizeName,
        volume: p.volume,
        priceAdjustment: p.priceAdjustment,
        isActive: p.isActive,
      }))
      .sort((a, b) => b.sizeName.localeCompare(a.sizeName)),
  };
};

export default function VariationPage() {
  const { productId } = useParams();
  const { currentUser } = useAuthStore();
  const {
    data: product,
    loading: productLoading,
    execute: refreshProduct,
  } = useRequest(
    () => productApi.getProduct(+productId!),
    [productId],
    !productId,
  );
  const { components = [], loading: componentsLoading } = useComponents();
  const { componentTypes = [], loading: componentTypesLoading } =
    useComponentTypes();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [isMutating, setIsMutating] = useState(false);
  const [showDisabled, setShowDisabled] = useState(false);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<
    number | undefined
  >();
  const [mutateVariantId, setMutateVariantId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const isLoading =
    productLoading ||
    componentsLoading ||
    componentTypesLoading ||
    isMutating;

  if (!hasAdminRole(currentUser)) return null;

  async function handleSave(mutatedVariant: MutateProductVariant) {
    setIsMutating(true);
    const errorText = `${mutatedVariant.id ? 'Edit' : 'Create'} Product Variant failed :(`;
    const successText = `Product Varaint ${mutatedVariant.id ? 'updated' : 'created'}!`;
    const mutateFn = mutatedVariant.id
      ? productVariantsApi.updateProductVariant
      : productVariantsApi.createProductVariant;
    try {
      await mutateFn(mutatedVariant);
      showAlert(successText, 'success');
      refreshProduct();
      setMutateVariantId(null);
    } catch (err) {
      console.error(err);
      showAlert(errorText, 'error');
    } finally {
      setIsMutating(false);
    }
  }

  async function statusToggle(variant: ProductVariant) {
    const mutateObj = mapProductVariantToMutateProductVariant(variant);
    mutateObj.isActive = !mutateObj.isActive;
    await handleSave(mutateObj);
  }

  function handlePageChange(_: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }

  const productVariants = filteredProductVariants(
    product,
    showDisabled,
    filterText,
    selectedProductTypeId,
  );
  const totalNumberOfPages = Math.ceil(
    productVariants.length / variationsPerPage,
  );
  const paginatedVariations = productVariants.slice(
    (currentPage - 1) * variationsPerPage,
    currentPage * variationsPerPage,
  );

  const selectedVariant = product?.productVariants?.find(
    (p) => p.id === mutateVariantId,
  );
  const mutateVariant = selectedVariant
    ? mapProductVariantToMutateProductVariant(selectedVariant)
    : product
      ? newProductVariant(product)
      : null;

  return (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <AdminHeader
        title={product?.name || 'Loading...'}
        description="Manage your product variations"
        buttonText="New Variant"
        buttonAction={() => setMutateVariantId(0)}
        showBackButton={true}
        onBackButtonClick={() => navigate('/admin/products')}
      />

      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <VariationFilter
          filterText={filterText}
          setFilterText={setFilterText}
          selectedComponentTypeId={selectedProductTypeId}
          setSelectedComponentTypeId={(category) => {
            setSelectedProductTypeId(category);
            setCurrentPage(1);
          }}
          showDisabled={showDisabled}
          setShowDisabled={setShowDisabled}
          componentTypes={componentTypes || []}
        />

        <Box
          ref={tableContainerRef}
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
          }}
        >
          <VariationTable
            isLoading={isLoading}
            paginatedVariations={paginatedVariations}
            statusToggle={statusToggle}
            editProduct={(variant) => setMutateVariantId(variant.id)}
          />
        </Box>

        <Box
          sx={{
            borderTop: 1,
            borderColor: 'divider',
            py: 2,
            backgroundColor: 'background.paper',
          }}
        >
          <Stack spacing={2} direction="row" justifyContent="center">
            <Pagination
              count={totalNumberOfPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </Stack>
        </Box>
      </Stack>

      {!!product && mutateVariantId !== null && !!mutateVariant && (
        <ProductVariantModal
          productVariant={mutateVariant}
          onClose={() => setMutateVariantId(null)}
          onSave={handleSave}
          components={components || []}
          componentTypes={componentTypes || []}
        />
      )}
    </Stack>
  );
}
